<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  alertId = prop({
    type: String,
    required: true,
  });
  show = prop({
    type: Boolean,
    required: true,
  });
  type = prop({
    type: String,
    required: true,
    validator: (aType: string): boolean => {
      return ['info', 'success', 'error'].includes(aType);
    },
  });
  text = prop({
    type: String,
    required: true,
  });
}

export default class AlertBlock extends Vue.with(Props) {}
</script>

<template>
  <div v-show="show" :id="`alert-${alertId}`" class="alert">
    <div id="notification" :class="`notification ${type}`">
      <div id="alert-text" class="content">
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/css/global.scss';

.alert {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  color: $darkgrey;
  font-weight: bold;
}

.notification {
  display: flex;
  position: relative;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;

  &:before {
    display: inline-flex;
    flex-shrink: 0;
    content: '';
    background-repeat: no-repeat;
    background-size: 25px auto;
    background-position: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.content {
  border: 1px solid;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 15px;
  margin-left: 30px;
  width: 100%;
  white-space: pre-line;

  p {
    margin-top: 0;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.error {
  .content {
    border-color: transparent;
    background-color: $red;
  }
  &:before {
    background-color: $darkred;
    background-image: url('/src/assets/img/alert-error.svg');
  }
}

.success {
  .content {
    border: transparent;
    background-color: $lightgreen;
  }
  &:before {
    background-color: $darkgreen;
    background-image: url('/src/assets/img/alert-success.svg');
  }
}

.info {
  .content {
    border-color: transparent;
    background-color: $lightblue;
  }
  &:before {
    background-color: $darkblue;
    background-image: url('/src/assets/img/alert-info.svg');
    background-size: 20px auto;
  }
}
</style>
