<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { User } from '../../models';
import { useStore } from '../../store';
import eosInsightlogo from '../../assets/img/eos-insight-logo.svg';
import { RoleVerifierService } from '../../services';
import { useUserTierPermissions } from '../../composables/useUserTierPermissions';
import LogoutButton from '../../components/common/logout/logout-button.vue';

@Options({
  components: {
    LogoutButton,
  },
})
export default class NavigatorBarVerticalView extends Vue {
  RoleVerifierService = RoleVerifierService;
  userInitials = '';
  store = useStore();
  currentRoute = '';
  eosInsightlogo = eosInsightlogo;
  userTierPermissions = useUserTierPermissions();

  async created(): Promise<void> {
    this.$watch(
      'currentUser',
      () => {
        this.userInitials = this.getInitials(this.currentUser);
      },
      {
        immediate: true,
      }
    );
  }

  get currentUser(): User {
    return this.store.currentUserInfo;
  }

  mounted() {
    this.getInitials(this.currentUser);
  }

  getInitials(currentUser: any) {
    const name = String(currentUser?.firstName).split(' ');
    const lastName = String(currentUser?.lastName).split(' ');
    return (
      `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}` +
      `${lastName[0].charAt(0)}${lastName[1] ? lastName[1].charAt(0) : ''}`
    );
  }
}
</script>

<template>
  <ul class="navigation-bar-vertical">
    <img :src="eosInsightlogo" alt="Eos Insight Logo" class="nav-logo" />
    <div
      v-if="RoleVerifierService.isSalesRep()"
      style="min-height: 18px"
      data-note="for disclaimer"
    ></div>
    <ul class="top">
      <li
        v-if="RoleVerifierService.isAdmin()"
        id="navigation-users"
        :title="$t('administrators.pageTitleList')"
      >
        <router-link
          to="/users"
          :class="this.$route.name.includes('user') ? 'router-link-active' : ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="45px"
            viewBox="0 0 24 24"
            fill="#F5F5F5"
          >
            <rect fill="none" />
            <g>
              <path
                d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z"
              />
            </g>
          </svg>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isAdmin()"
        id="navigation-institutions"
        :title="$t('institutions.pageTitle')"
      >
        <router-link
          to="/institutions"
          :class="
            this.$route.name.includes('institution') &&
            !this.$route.name.includes('physician')
              ? 'router-link-active'
              : ''
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 45"
            fill="#F5F5F5"
            height="33px"
          >
            <g>
              <path
                class="cls-1"
                d="M53.02,9h-10.55c-1.09,0-1.98-.88-1.98-1.98V1.98c0-1.09-.88-1.98-1.98-1.98H16.48c-1.09,0-1.98,.88-1.98,1.98V7.02c0,1.09-.88,1.98-1.98,1.98H1.98c-1.09,0-1.98,.88-1.98,1.98V43.02c0,1.09,.88,1.98,1.98,1.98H53.02c1.09,0,1.98-.88,1.98-1.98V10.98c0-1.09-.88-1.98-1.98-1.98ZM15.5,37.01c0,.55-.44,.99-.99,.99h-4.02c-.55,0-.99-.44-.99-.99v-4.02c0-.55,.44-.99,.99-.99h4.02c.55,0,.99,.44,.99,.99v4.02Zm0-11c0,.55-.44,.99-.99,.99h-4.02c-.55,0-.99-.44-.99-.99v-4.02c0-.55,.44-.99,.99-.99h4.02c.55,0,.99,.44,.99,.99v4.02Zm15,11c0,.55-.44,.99-.99,.99h-4.02c-.55,0-.99-.44-.99-.99v-4.02c0-.55,.44-.99,.99-.99h4.02c.55,0,.99,.44,.99,.99v4.02Zm0-11c0,.55-.44,.99-.99,.99h-4.02c-.55,0-.99-.44-.99-.99v-4.02c0-.55,.44-.99,.99-.99h4.02c.55,0,.99,.44,.99,.99v4.02Zm1.5-14c0,.55-.44,.99-.99,.99h-2.01v1.96c0,.57-.46,1.04-1.04,1.04h-.93c-.57,0-1.04-.46-1.04-1.04v-1.96h-2.01c-.55,0-.99-.44-.99-.99v-1.02c0-.55,.44-.99,.99-.99h2.01v-1.96c0-.57,.46-1.04,1.04-1.04h.93c.57,0,1.04,.46,1.04,1.04v1.96h2.01c.55,0,.99,.44,.99,.99v1.02Zm13.5,25c0,.55-.44,.99-.99,.99h-4.02c-.55,0-.99-.44-.99-.99v-4.02c0-.55,.44-.99,.99-.99h4.02c.55,0,.99,.44,.99,.99v4.02Zm0-11c0,.55-.44,.99-.99,.99h-4.02c-.55,0-.99-.44-.99-.99v-4.02c0-.55,.44-.99,.99-.99h4.02c.55,0,.99,.44,.99,.99v4.02Z"
              />
            </g>
          </svg>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isInstitutionUser()"
        v-tooltip.right="
          userTierPermissions.isActionsMenuBarDisabled
            ? $t('global.upgradeToTier3')
            : ''
        "
        id="navigation-actions"
        :title="$t('actions.pageTitle')"
      >
        <router-link
          to="/actions"
          :class="
            userTierPermissions.isActionsMenuBarDisabled
              ? 'router-link-disabled'
              : ''
          "
        >
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-bar-actions.svg"
                :alt="$t('actions.pageTitle')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('actions.pageTitle') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isInstitutionUser()"
        id="navigation-patients"
        :title="$t('patients.pageTitle')"
        v-tooltip.right="
          userTierPermissions.isPatientsMenuBarDisabled
            ? $t('global.upgradeToTier3')
            : ''
        "
      >
        <router-link
          to="/patient/list"
          :class="[
            this.$route.name.includes('patient-list') ||
            this.$route.name.includes('detail-patient')
              ? 'router-link-active'
              : '',
            userTierPermissions.isPatientsMenuBarDisabled
              ? 'router-link-disabled'
              : '',
          ]"
        >
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-bar-patients.svg"
                :alt="$t('patients.pageTitle')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('patients.pageTitle') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li
        id="navigation-calendar"
        :title="$t('caseCalendar.calendar')"
        v-if="RoleVerifierService.isInstitutionUser()"
        v-tooltip.right="
          userTierPermissions.isCalendarMenuBarDisabled
            ? $t('global.upgradeToTier3')
            : ''
        "
      >
        <router-link
          to="/calendar"
          :class="
            userTierPermissions.isCalendarMenuBarDisabled
              ? 'router-link-disabled'
              : ''
          "
        >
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-bar-calendar.svg"
                :alt="$t('caseCalendar.calendar')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('caseCalendar.calendar') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isInstitutionUser()"
        v-tooltip.right="$t('analytics.comingSoon')"
        id="navigation-analytics"
        :title="$t('analytics.pageTitle')"
      >
        <router-link
          to="/analytics"
          :class="
            userTierPermissions.isAnalyticsMenuBarDisabled
              ? 'router-link-disabled'
              : 'router-link-disabled'
          "
        >
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/analytics-icon.svg"
                :alt="$t('analytics.pageTitle')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('analytics.pageTitle') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isTechnician()"
        id="navigation-cases"
        :title="$t('cases.pageTitle')"
      >
        <router-link
          to="/case-list"
          :class="
            this.$route.path.includes('/case-list') ||
            this.$route.path.includes('/case/history')
              ? 'router-link-active'
              : ''
          "
        >
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-bar-cases.svg"
                :alt="$t('cases.pageTitle')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('cases.pageTitle') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isTechnicianManager()"
        id="navigation-analytics"
        :title="$t('analytics.pageTitle')"
      >
        <router-link to="/case/analytics">
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-bar-analytics.svg"
                :alt="$t('analytics.pageTitle')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('analytics.pageTitle') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li
        v-if="RoleVerifierService.isRodManufacturer()"
        id="rod-orders"
        :title="$t('rodOrdering.pageTitle')"
      >
        <router-link to="/orders">
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-bar-patients.svg"
                :alt="$t('rodOrdering.pageTitle')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('rodOrdering.pageTitle') }}</span>
            </div>
          </div>
        </router-link>
      </li>
    </ul>

    <ul class="bottom">
      <li :title="$t('global.info')">
        <router-link to="/info">
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-info.svg"
                :alt="$t('global.info')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('global.info') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <li id="navigation-userprofile" :title="$t('global.account.label')">
        <router-link
          to="/profile"
          :class="
            this.$route.name.includes('user-profile')
              ? 'router-link-active'
              : ''
          "
        >
          <div class="testLink">
            <div class="testLink__icon">
              <img
                src="../../assets/img/nav-account.svg"
                :alt="$t('global.account.label')"
              />
            </div>
            <div class="testLink__text">
              <span>{{ $t('global.account.label') }}</span>
            </div>
          </div>
        </router-link>
      </li>
      <LogoutButton>
        <div class="testLink">
          <div class="testLink__icon">
            <img
              src="../../assets/img/nav-logout.svg"
              :alt="$t('global.logout')"
            />
          </div>
          <div class="testLink__text">
            <span>{{ $t('global.logOut') }}</span>
          </div>
        </div>
      </LogoutButton>
    </ul>
  </ul>
</template>

<style lang="scss" scoped>
@import '../../assets/css/global.scss';

[data-initials]:before {
  background: transparent;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-color: white;
  border-width: 0.2em;
  border-style: solid;
}

.navigation-bar-vertical {
  list-style-type: none;
  position: fixed;
  width: $navBarWidth;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $black;
  z-index: 2;
}
.nav-logo {
  margin: 12px 0 10px -4px;
  width: 68px;
}

.top {
  list-style-type: none;
  padding: 0;
}

.bottom {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2rem;
}

@media all and (max-height: 500px) {
  .top {
    margin: 0;
  }
  .bottom {
    bottom: 0;
  }
}

ul {
  width: $navBarWidth;
}

li a {
  display: block;
  padding: 8px;
  text-decoration: none !important;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
    background: $darkgreytwo;
  }
}

li a.router-link-active {
  padding-left: 5px;
  background-color: $navbargreen;
  border-left: 3px solid $brightgreen;
  transition: all 0.3s ease;
  svg {
    fill: $lightgrey;
  }
}
.router-link-active {
  padding-left: 5px;
  background-color: $navbargreen;
  border-left: 3px solid $brightgreen;
  transition: all 0.3s ease;
  svg {
    fill: $lightgrey;
  }
}
li:hover {
  cursor: pointer;
  svg {
    fill: $lightgrey;
  }
}
.router-link-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default !important;
}

.testLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  &__icon {
    display: flex;
    img {
      width: 24px;
      height: 24px;
      fill: $darkgreytwo;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
    color: $white;
  }
}
</style>
