<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import ConnectionStatusExperienced from '../../../assets/img/connection-status-experienced.svg';
import { User } from '../../../models/user';
import { RoleVerifierService } from '../../../services';
import SubmenuContainer from './submenu-container.vue';
import UserMenuLoginDropdown from './user-menu-login-dropdown.vue';
import TextCondenser from '../text-condenser/text-condenser.vue';

interface Props {
  userInfo: User;
}
const props = defineProps<Props>();

const initials = computed(() => {
  return props.userInfo.firstName.charAt(0) + props.userInfo.lastName.charAt(0);
});
</script>

<template>
  <SubmenuContainer>
    <div class="user-menu-header-container">
      <router-link to="/profile">
        <div class="user-icon">
          {{ initials }}
          <UserMenuLoginDropdown
            v-if="
              RoleVerifierService.isTechnician() &&
              !RoleVerifierService.isTechnicianManager()
            "
            :user-info="userInfo"
            class="login-status-icon"
          />
        </div>
      </router-link>
      <div class="user-info">
        <TextCondenser
          :text="[userInfo.firstName, userInfo.lastName]"
          :max-length="20"
          style="display: flex; justify-content: center"
        />
        <img
          v-if="
            RoleVerifierService.isTechnician() &&
            !userInfo.userRoleProperties?.inexperienced &&
            !RoleVerifierService.isTechnicianManager()
          "
          class="experienced-user-icon"
          :src="ConnectionStatusExperienced"
          alt="experience type"
        />
      </div>
    </div>
  </SubmenuContainer>
</template>

<style lang="scss" scoped>
.user-menu-header-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100px;
  z-index: 10;
}

.user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.experienced-user-icon {
  width: 18px;
  height: 18px;
}
a {
  text-decoration: none;
}

.user-icon {
  position: relative;
  width: 45px;
  height: 45px;
  background-color: #015300;
  color: #77ff61;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.login-status-icon {
  position: absolute;
  right: -5px;
  bottom: -1px;
}
</style>
