export enum Sex {
  F = 'F',
  M = 'M',
  O = 'O',
}

export interface PersonName {
  familyName?: string;
  firstName?: string;
}

export interface Patient {
  id: string;
  patientID?: string;
  name?: PersonName;
  birthDate?: string;
  sex?: Sex;
}

export interface Study {
  id: string;
  referringPhysicianName?: PersonName;
  accessionNumber?: string;
  date?: string;
  description?: string;
  patient: Patient;
}

export interface Series {
  id: string;
  description?: string;
  deviceSerialNumber?: string;
  institutionName?: string;
  study: Study;
}

export interface Modeling {
  id: string;
  state: string;
  creationTimestamp: string;
  modelizedTimestamp?: string;
}

export interface Acquisition {
  id: string;
  state: string;
  date?: string;
  time?: string;
  series: Series;
  modelings: Modeling[];
}

export enum PatientExamExamType {
  EosFullBody = 'EOSFullBody',
  EosLowerLimb = 'EOSLowerLimb',
  Ioa = 'IOA',
  Vea = 'VEA',
  AlignmentReport = 'AlignmentReport',
  AlignmentExam = 'AlignmentExam',
}

export enum PatientExamStatusType {
  Processing = 'proccessing',
  CanApprove = 'canApprove',
  Approved = 'approved',
  ReadyToReview = 'readyToReview',
  Cancelled = 'cancelled',
  Warning = 'warning',
}

export interface PatientExamListItemReference {
  active: boolean;
  key: string;
}

export interface Image {
  id: string;
  title: string;
  link: string;
  date: Date;
  operationType?: string;
}

export interface PatientExam {
  guid: string;
  date: Date;
  title: string;
  status?: PatientExamStatusType;
  description: string;
  images: Array<Image>;
  type: PatientExamExamType;
}

export type ModelingState = 'success' | 'failure' | 'in progress';

export const EXAMS_DEFAULT_LIMIT = 20;

export const additionalAcquisitionListStub: Acquisition[] = [
  {
    id: '5c578399-554f-4c3f-a4a1-48a34ef6e359',
    state: 'EOS_BI_PLANE',
    date: '20220822',
    time: '121523',
    series: {
      id: '5dac0e06-99eb-45ae-870f-dd8f92f20183',
      description: 'X-Ray Scoliosis AP, Frontal',
      institutionName: 'Sacred Heart Hospital',
      study: {
        id: '20ebe029-4717-4684-87b0-d519abc1207e',
        referringPhysicianName: {
          familyName: 'Fox',
          firstName: 'Rene',
        },
        accessionNumber: 'A10024021610',
        date: '20220815',
        description: 'X-Ray Scoliosis AP, Frontal',
        patient: {
          id: '84af1561-5a03-4a6a-ba08-7304d4becd54',
          patientID: '9632',
          name: {
            familyName: 'Hernandez',
            firstName: 'Lucas',
          },
          sex: Sex.M,
        },
      },
    },
    modelings: [
      {
        id: '993687f9-5784-467e-9449-aebe75c79fc9',
        state: 'AUTO3D',
        creationTimestamp: '2022-08-19T10:14:17.275Z',
        modelizedTimestamp: '2022-08-19T10:14:17.275Z',
      },
    ],
  },
  {
    id: '62660177-393d-4896-a4dd-5462e79e8ce8',
    state: '',
    date: '20220823',
    time: '141523',
    series: {
      id: 'e5a46f56-d0df-4c73-8e52-a94a530ab311',
      description: 'X-Ray EOS Whole Body AP Lateral (Biplane)',
      institutionName: 'EOS Medical Center',
      study: {
        id: 'dacd57d1-3f44-4f18-810c-7e3fd5105d17',
        referringPhysicianName: {
          familyName: 'Wolf',
          firstName: 'Toto',
        },
        accessionNumber: 'A14785236975',
        date: '20220719',
        description: 'X-Ray EOS Whole Body AP Lateral (Biplane)',
        patient: {
          id: '71e10b0c-32e9-461d-b206-e0f42a64f4cc',
          patientID: '3210',
          name: {
            familyName: 'Upamecano',
            firstName: 'Dayot',
          },
          birthDate: '19981027',
          sex: Sex.M,
        },
      },
    },
    modelings: [],
  },
  {
    id: '1744d4f0-9213-4417-ac58-1cab8860ae00',
    state: '',
    date: '20220822',
    time: '121523',
    series: {
      id: '9bfbe453-de88-46f3-81f6-b2dba623ecaa',
      description: 'X-Ray Scoliosis AP, Lateral',
      institutionName: 'Lotus Listing',
      study: {
        id: '0af2323c-9674-474c-9e9a-a25bee3caafb',
        referringPhysicianName: {
          familyName: 'Aragao',
          firstName: 'Neiva',
        },
        accessionNumber: 'A91738264508',
        date: '20220710',
        description: 'X-Ray Scoliosis AP, Lateral',
        patient: {
          id: '6dc21134-4286-4444-8480-21506fda7f53',
          patientID: '7896',
          name: {
            familyName: 'Davies',
            firstName: 'Alphonso',
          },
          birthDate: '20001102',
          sex: Sex.M,
        },
      },
    },
    modelings: [
      {
        id: '8a67333d-1dc1-4f70-afdf-8a6c14b8b273',
        state: 'IA_IN_PROGRESS',
        creationTimestamp: '2022-07-19T10:14:17.275Z',
        modelizedTimestamp: '2022-07-19T10:14:17.275Z',
      },
    ],
  },
];
