import { AboutConfig } from '../../../src/models';
import { ConfigType, ExternalServiceType } from './config-types';
import theConfig from './config.json';
import { ConfigurationOverrider } from './configuration-overrider';

/**
 * Singleton class exposing workspace configuration parameters.
 */
export class WorkspaceConfig {
  private static pInstance?: WorkspaceConfig;

  /**
   * Enables to access to the singleton object.
   */
  static instance(): WorkspaceConfig {
    if (WorkspaceConfig.pInstance === undefined) {
      if (window.__env__ !== undefined) {
        const aConfigOverrider = new ConfigurationOverrider();
        aConfigOverrider.overrideProperties(window.__env__, theConfig);
      }
      WorkspaceConfig.pInstance = new WorkspaceConfig(theConfig);
    }
    return WorkspaceConfig.pInstance;
  }

  /**
   * Enables to reset the singleton object.
   */
  static destroy(): void {
    WorkspaceConfig.pInstance = undefined;
  }

  /**
   * Returns app name property from the config.
   */
  appName(): string {
    return `${(this.config.app as ConfigType).name}`;
  }

  appVersion(): string {
    const version = import.meta.env.VITE_EOS_INSIGHT_VERSION ?? 'xx.xx.xx';
    return version;
  }

  /**
   * Returns app about namespace property from the config.
   */
  appAboutNamespace(): string {
    return `${((this.config.app as ConfigType).about as ConfigType).namespace}`;
  }

  appAboutConfig(): AboutConfig {
    return (this.config.app as ConfigType).about as unknown as AboutConfig;
  }

  /**
   * Returns dev mode property from the config.
   */
  isDevMode(): boolean {
    return (this.config.app as ConfigType).mode === 'dev';
  }

  isLocalMode(): boolean {
    return (this.config.app as ConfigType).mode === 'local';
  }

  isComingSoonEnabled(): boolean {
    return (this.config.app as ConfigType).coming_soon as boolean;
  }

  /**
   * Returns the basePath from the config of an external runtime dependency.
   *
   * @param service External service identifier.
   */
  getBasePath(service: ExternalServiceType): string {
    return `${
      ((this.config.runtimeDependencies as ConfigType)[service] as ConfigType)
        .basePath
    }`;
  }

  /**
   * Builds this object.
   *
   * @param config Provides a default configuration object.
   */
  private constructor(private readonly config: ConfigType) {
    // Empty
  }
}
