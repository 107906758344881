import { computed } from 'vue';
import { useNetwork } from '@vueuse/core';
import { defineStore } from 'pinia';

export const useOfflineDetectionStore = defineStore('offlineDetection', () => {
  const { isOnline, isSupported } = useNetwork();

  const isOffline = computed(() => {
    return isSupported.value && !isOnline.value;
  });

  return {
    isOffline,
  };
});
