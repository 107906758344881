<script lang="ts" setup>
import { RoleIdBasedOnRoleName, User } from '../../../models';
import SubmenuContainer from './submenu-container.vue';
import SubmenuItem from './submenu-item.vue';
import { computed } from 'vue';

interface Props {
  userConnections: Array<User>;
}
const props = defineProps<Props>();

const clinicalStaff = computed(() => {
  return props.userConnections.filter((u) =>
    compareUserRoleByRoleId(u, RoleIdBasedOnRoleName.CLINICAL_STAFF)
  );
});

const salesReps = computed(() => {
  return props.userConnections.filter((u) =>
    compareUserRoleByRoleId(u, RoleIdBasedOnRoleName.SALES_REPRESENTATIVE)
  );
});

function compareUserRoleByRoleId(user: User, role: RoleIdBasedOnRoleName) {
  return user.roleIds.includes(role);
}
</script>

<template>
  <SubmenuContainer :title="$t('userProfile.connectionLabels')">
    <SubmenuItem type="menu" data-cy="clinicalStaffMenu">
      {{ $t('administrators.role.CLINICAL STAFF') }}
      <template #menu>
        <SubmenuItem v-for="staff in clinicalStaff">
          <div class="sub-menu-connections-container">
            <div class="institution-title">{{ staff.institutionName }}</div>
            <div>{{ staff.firstName }} {{ staff.lastName }}</div>
          </div>
        </SubmenuItem>
      </template>
    </SubmenuItem>
    <SubmenuItem type="menu" data-cy="salesRepMenu">
      {{ $t('administrators.role.SALES REPRESENTATIVE') }}
      <template #menu>
        <SubmenuItem v-for="rep in salesReps">
          <div class="sub-menu-connections-container">
            <div class="institution-title">{{ rep.institutionName }}</div>
            <div class="name-title">{{ rep.firstName }} {{ rep.lastName }}</div>
          </div>
        </SubmenuItem>
      </template>
    </SubmenuItem>
  </SubmenuContainer>
</template>

<style lang="scss" scoped>
.sub-menu-connections-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.institution-title {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.name-title {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
</style>
