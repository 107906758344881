import { WorkspaceConfig } from '../assets/config';
import { WorkspaceLogger } from '../logger';
import { PaginatedResult } from '../models';
import { UtilsService } from '../services';

const PATIENT_LIST_BASE_PATH =
  WorkspaceConfig.instance().getBasePath('PatientListSvc');

export async function getExams(queryParams?: {
  [x: string]: string | number;
}): Promise<PaginatedResult | undefined> {
  const destUrl = `${
    location.origin
  }/${PATIENT_LIST_BASE_PATH}/api/v1/acquisitions${UtilsService.buildQueryString(
    queryParams
  )}`;
  const exams: PaginatedResult | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return exams;
}

export const listenedWebsocketStatus = [
  'NewAcquisitionRegistered',
  'NewModelingRegistered',
];
