import { WorkspaceConfig } from '../assets/config';
import { WorkspaceLogger } from '../logger';
import { Application } from '../models';
import { UtilsService } from '../services';

const APPLICATION_LAUNCHER_BASE_PATH = WorkspaceConfig.instance().getBasePath(
  'ApplicationLauncherSvc'
);

export async function getApplications(): Promise<
  Array<Application> | undefined
> {
  const destUrl = `${location.origin}/${APPLICATION_LAUNCHER_BASE_PATH}/api/v1/applications`;
  const applications: Array<Application> | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return applications;
}

export async function getLaunch(
  appId: string,
  queryParams?: {
    [x: string]: string;
  }
): Promise<string | void> {
  const destUrl = `${
    location.origin
  }/${APPLICATION_LAUNCHER_BASE_PATH}/api/v1/launch/${appId}${UtilsService.buildQueryString(
    queryParams
  )}`;
  const launchUrl: string | void = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.text())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return launchUrl;
}
