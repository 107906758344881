import { useCookies } from 'vue3-cookies';
import { useSessionStorage } from '@vueuse/core';
import { computed } from 'vue';

export function useMobileAuthentication() {
  const { cookies } = useCookies();

  const authCookie = useSessionStorage('e_hosted_jwt', '');

  const headers = computed(() => {
    return authCookie.value
      ? { Authorization: 'Bearer ' + authCookie.value }
      : undefined;
  });

  function init() {
    authCookie.value = cookies.get('e_hosted_jwt');
  }

  function destroy() {
    cookies.remove('e_hosted_jwt');
    authCookie.value = '';
  }

  return {
    authCookie,
    headers,
    init,
    destroy,
  };
}
