<script lang="ts" setup>
import { computed, ref } from 'vue';
import UserMenuArrow from '../../../assets/img/user-menu-arrow.svg';

interface Props {
  type?: 'menu';
}
const props = defineProps<Props>();

const isHover = ref(false);

const isTypeMenu = computed(() => {
  return props.type === 'menu';
});

function handleHover(isOver: boolean) {
  isHover.value = isOver;
}
</script>

<template>
  <div
    class="submenu-item"
    :class="isTypeMenu ? 'submenu-menu' : ''"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
  >
    <slot></slot>
    <img v-if="isTypeMenu" :src="UserMenuArrow" alt="open" />

    <div v-if="isHover" class="offset-submenu">
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.submenu-item {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  height: 56px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 100ms;
  &:hover {
    background-color: #43483f;
  }
}

.offset-submenu {
  position: absolute;
  right: 100%;
  width: 300px;
  background-color: #101e12;
  max-height: 560px;
  overflow-y: auto;
}

.submenu-menu {
  justify-content: space-between;
}
</style>
