<script lang="ts" setup>
import { computed, defineEmits, defineProps, useSlots } from 'vue';
import BaseBtn from '../button/base-btn.vue';
import ModalPopup from './modal-popup.vue';
import { ErrorWithStatus } from '../../../models';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const slots = useSlots();

const itemsInFooterSlot = computed(() => slots.footer !== undefined);

interface Props {
  title?: string;
  error?: ErrorWithStatus;
}
defineProps<Props>();

const emit = defineEmits(['close']);

const router = useRouter();
</script>
<template>
  <ModalPopup
    :title="title || $t('global.error.somethingWentWrong')"
    :icon-type="'warning'"
  >
    <template #body>
      <div v-if="error">{{ error.message }}</div>
    </template>
    <template #footer>
      <slot name="footer"></slot>
      <template v-if="!itemsInFooterSlot">
        <BaseBtn class="btn-outline" @click="router.go(0)">
          {{ $t('global.button.refresh') }}
        </BaseBtn>
        <BaseBtn class="btn-primary" @click="$emit('close')">
          {{ $t('global.button.cancel') }}
        </BaseBtn>
      </template>
    </template>
  </ModalPopup>
</template>
