import { WorkspaceConfig } from '../assets/config';
import { WorkspaceLogger } from '../logger';
import { UserAuthzInfo } from '../models';

import * as UtilsService from './utils-service';
import { useMobileAuthentication } from '../composables/useMobileAuthentication';

const {
  authCookie,
  headers,
  destroy: destroyMobileAuth,
} = useMobileAuthentication();

const AUTHENTICATION_BASE_PATH =
  WorkspaceConfig.instance().getBasePath('AuthenticationSvc');

export async function userinfo(): Promise<UserAuthzInfo> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/userinfo`;

  const userInfo: UserAuthzInfo = await fetch(destUrl, {
    headers: headers.value,
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
    });

  return userInfo;
}

export async function refreshSession(): Promise<void> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/refresh-session`;
  await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.ok);

  return;
}

export async function logout(): Promise<void> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/logout?redirectUrl=${location.origin}${location.pathname}`;
  sessionStorage.clear();

  if (authCookie.value) destroyMobileAuth();

  window.location.href = destUrl;
}

export async function login(): Promise<void> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/login?redirectUrl=${location.origin}${location.pathname}`;
  window.location.href = destUrl;
}
