export function buildWebsocketServerUrl(
  protocol: string,
  host: string,
  path: string,
  ...rest: Array<string>
): string {
  return [
    protocol === 'http:' ? 'ws:/' : 'wss:/',
    host,
    path,
    ...rest,
    '',
  ].join('/');
}

export function connectWebsocket(
  config: { url: string },
  eventListeners: {
    open?: (event: Event) => void;
    message?: (event: MessageEvent) => void;
    close?: (event: CloseEvent) => void;
    error?: (event: Event) => void;
  }
): WebSocket {
  const webSocketClient = new WebSocket(config.url);
  if (eventListeners.open !== undefined) {
    webSocketClient.addEventListener('open', eventListeners.open);
  }
  if (eventListeners.message !== undefined) {
    webSocketClient.addEventListener('message', eventListeners.message);
  }
  if (eventListeners.close !== undefined) {
    webSocketClient.addEventListener('close', eventListeners.close);
  }
  if (eventListeners.error !== undefined) {
    webSocketClient.addEventListener('error', eventListeners.error);
  }
  return webSocketClient;
}
