<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue';
import ConnectionStatusExperienced from '../../../assets/img/connection-status-experienced.svg';
import ConnectionStatusOffline from '../../../assets/img/connection-status-offline.svg';
import ConnectionStatusOnline from '../../../assets/img/connection-status-online.svg';
import ConnectionStatusPaused from '../../../assets/img/connection-status-paused.svg';
import { OnlineStatusEnum, User } from '../../../models/user';
import '@material/web/radio/radio';
import '@material/web/checkbox/checkbox';
import BarLoader from '../loader/bar-loader/bar-loader.vue';
import SubmenuContainer from './submenu-container.vue';
import WorkflowConfirmOnlineStatusChange from './workflows/workflow-confirm-online-status-change.vue';

interface Props {
  userInfo: User;
  isOnlineLoading: boolean;
  isExperiencedLoading: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['onlineStatusChanged', 'experienceChanged']);

const showWorkflowConfirmOnlineStatusChangeModal = ref(false);

const onlineStatus = computed(() => {
  return props.userInfo.userRoleProperties?.onlineStatus;
});

const isRadioButtonsDisabled = computed(() => {
  return props.isOnlineLoading;
});

const potentialStatusChange = ref<OnlineStatusEnum>();
function handleOnlineStatusChange(e: any) {
  if (e.target.value === onlineStatus.value) {
    return;
  } else if (e.target.value === OnlineStatusEnum.Online) {
    emit('onlineStatusChanged', e.target.value as OnlineStatusEnum);
    return;
  } else {
    showWorkflowConfirmOnlineStatusChangeModal.value = true;
    potentialStatusChange.value = e.target.value as OnlineStatusEnum;
  }
}

function handleConfirmOnlineStatusChange() {
  showWorkflowConfirmOnlineStatusChangeModal.value = false;
  emit('onlineStatusChanged', potentialStatusChange.value);
  potentialStatusChange.value = undefined;
}

function handleCancelConfirmOnlineStatusChange() {
  showWorkflowConfirmOnlineStatusChangeModal.value = false;
  potentialStatusChange.value = undefined;
}

function handleExperiencedUserChange(e: any) {
  emit('experienceChanged', e.target.checked as boolean);
}
</script>

<template>
  <WorkflowConfirmOnlineStatusChange
    v-if="showWorkflowConfirmOnlineStatusChangeModal"
    :online-status="potentialStatusChange"
    @confirm-approval-and-release="handleConfirmOnlineStatusChange"
    @cancel="handleCancelConfirmOnlineStatusChange"
  />

  <BarLoader :is-loading="isOnlineLoading || isExperiencedLoading" />
  <SubmenuContainer title="Connection Status">
    <ul>
      <li class="connection-option-container">
        <div class="option-title-image">
          <img
            class="option-image"
            :src="ConnectionStatusOnline"
            alt="online"
          />
          <div>{{ $t('userMenu.status.online') }}</div>
        </div>
        <md-radio
          :name="OnlineStatusEnum.Online"
          :value="OnlineStatusEnum.Online"
          :checked="
            onlineStatus === OnlineStatusEnum.Online && !isOnlineLoading
          "
          @change="handleOnlineStatusChange"
          :disabled="isRadioButtonsDisabled"
        ></md-radio>
      </li>

      <li class="connection-option-container">
        <div class="option-title-image">
          <img
            class="option-image"
            :src="ConnectionStatusOffline"
            alt="online"
          />
          <div>{{ $t('userMenu.status.offline') }}</div>
        </div>
        <md-radio
          :name="OnlineStatusEnum.Offline"
          :value="OnlineStatusEnum.Offline"
          :checked="
            onlineStatus === OnlineStatusEnum.Offline && !isOnlineLoading
          "
          @change="handleOnlineStatusChange"
          :disabled="isRadioButtonsDisabled"
        ></md-radio>
      </li>

      <li class="connection-option-container">
        <div class="option-title-image">
          <img
            class="option-image"
            :src="ConnectionStatusPaused"
            alt="online"
          />
          <div>{{ $t('userMenu.status.hold') }}</div>
        </div>
        <md-radio
          :name="OnlineStatusEnum.Paused"
          :value="OnlineStatusEnum.Paused"
          :checked="
            onlineStatus === OnlineStatusEnum.Paused && !isOnlineLoading
          "
          @change="handleOnlineStatusChange"
          :disabled="isRadioButtonsDisabled"
        ></md-radio>
      </li>

      <li class="connection-option-container">
        <div class="option-title-image">
          <img
            class="option-image"
            :src="ConnectionStatusExperienced"
            alt="online"
          />
          <div>{{ $t('userMenu.status.experiencedUser') }}</div>
        </div>
        <md-checkbox
          @input="handleExperiencedUserChange"
          :disabled="isExperiencedLoading"
          :checked="
            !userInfo.userRoleProperties?.inexperienced && !isExperiencedLoading
          "
        ></md-checkbox>
      </li>
    </ul>
  </SubmenuContainer>
</template>

<style lang="scss" scoped>
.user-menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 20px;
  width: 300px;
  background-color: #101e12;
  z-index: 10;
}

.option-title-image {
  display: flex;
  align-items: center;
  gap: 13px;
}

.option-image {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
}

ul {
  margin: 0;
  padding: 0;

  :last-child::after {
    border-bottom: none !important;
  }
}
.connection-option-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;

  &::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: '';
    border-bottom: 1px solid rgba(219, 219, 219, 0.2);
  }
}
</style>
