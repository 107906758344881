import { createI18n } from 'vue-i18n';
import enUS from './en-US.json';
import frFR from './fr-FR.json';

export const messages = {
  'en-US': enUS,
  'fr-FR': frFR,
};

const DEFAULT_LOCALE = 'en-US';

export const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  // Must be set to 'false', to use Composition API
  // legacy: false,
  // Refer a global scope Composer instance of i18n
  globalInjection: true,
  messages,
});

// export default i18n;
export const i18nGlobalInstance = i18n.global;
