import { WorkspaceLogger } from '../logger';

import * as UtilsService from './utils-service';

export async function importDicomFile(
  dicomFile: File
): Promise<boolean | void> {
  const formData = new FormData();
  formData.append('file', dicomFile);
  const destUrl = `${location.origin}/api/acquisitions/store`;
  const status: boolean | void = await fetch(destUrl, {
    method: 'POST',
    body: formData,
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
    });

  return status;
}
