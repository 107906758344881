import { RoleName } from '../models';
import { useStore } from '../store';

export function isSysAdmin(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.SYSTEM_ADMIN}`
  );
}

export function isUserAdmin(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.USER_ADMIN}`
  );
}

export function isAdmin(): boolean {
  return isSysAdmin() || isUserAdmin();
}

export function isPhysician(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.PHYSICIAN}`
  );
}

export function isClinicalStaff(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.CLINICAL_STAFF}`
  );
}

export function isSalesRep(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.SALES_REPRESENTATIVE}`
  );
}
export function isInstitutionUser(): boolean {
  return isPhysician() || isClinicalStaff() || isSalesRep();
}

export function isTechnician(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.TECHNICIAN}`
  );
}

export function isTechnicianManager(): boolean {
  const store = useStore();
  if (
    isTechnician() &&
    store.currentUserInfo.userRoleProperties?.subRole === 'Manager'
  ) {
    return true;
  }

  return false;
}

export function isTechnicianInexperienced(): boolean {
  const store = useStore();
  if (
    isTechnician() &&
    store.currentUserInfo.userRoleProperties?.inexperienced === true
  ) {
    return true;
  }

  return false;
}

export function isRodManufacturer(): boolean {
  const store = useStore();
  return store.currentUserInfo.roles.includes(
    `${RoleName.DEFAULT_NAMESPACE}:${RoleName.ROD_MANUFACTURER}`
  );
}

export function isUserIdentified(): boolean {
  const store = useStore();
  return Object.keys(store.currentUserInfo).length > 0;
}

export function roleValidator(
  userRoleId: string,
  userRoleName: string
): boolean {
  const store = useStore();
  const role = store.roles.find((role) => role.roleId === userRoleId);
  return role !== undefined && role.name === userRoleName;
}
