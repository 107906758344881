import { VueLogger } from 'vue-logger-plugin';

import { LogPayload } from './log-types';
import logger from './index';

/**
 * Wrapper around vue logger pluggin.
 * Enables to provide a bit more type restriction around
 * the log data payload.
 */
export class WorkspaceLogger {
  private static pInstance?: WorkspaceLogger;

  static instance(): WorkspaceLogger {
    if (WorkspaceLogger.pInstance === undefined) {
      WorkspaceLogger.pInstance = new WorkspaceLogger(logger);
    }
    return WorkspaceLogger.pInstance;
  }

  /**
   * Enables to reset the singleton object.
   */
  static destroy(): void {
    WorkspaceLogger.pInstance = undefined;
  }

  /**
   * Enables to activate or disable the logging.
   *
   * @param isEnabled True when logging should be activated else false.
   */
  enable(isEnabled: boolean): void {
    this.pLogger.apply({
      enabled: isEnabled,
    });
  }

  /**
   * Triggers logging.
   *
   * @param payload Represents the log data payload.
   */
  log(payload: LogPayload): void {
    switch (payload.level) {
      case 'DEBUG':
        this.pLogger.debug(payload);
        break;
      case 'WARNING':
        this.pLogger.warn(payload);
        break;
      case 'INFO':
        this.pLogger.info(payload);
        break;
      case 'ERROR':
        this.pLogger.error(payload);
        break;
      case 'CRITICAL':
        this.pLogger.error(payload);
        break;
      default:
        payload.level = 'INFO';
        this.pLogger.info(payload);
        break;
    }
  }

  private constructor(private readonly pLogger: VueLogger) {
    // Empty
  }
}
