export enum ErrorModalButtonEnum {
  Refresh = 'Refresh',
  Cancel = 'Cancel',
}

export interface ErrorModalInformation {
  isError: boolean;
  code: number;
  title: string;
  text: string;
  buttons: ErrorModalButtonEnum[];
}
