<script lang="ts">
import { Vue, prop } from 'vue-class-component';

class Props {
  showLoader = prop({
    type: Boolean,
    required: true,
  });
}

export default class Loader extends Vue.with(Props) {}
</script>

<template>
  <div class="loader" v-show="showLoader" style="background: transparent">
    <img src="../../../assets/img/loader.svg" alt="loader" />
  </div>
</template>

<style scoped>
.loader {
  animation: animate_loader 1s ease-in;
  margin: 0 auto;
  width: 60px;
}

@keyframes animate_loader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
