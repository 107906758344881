<script lang="ts" setup>
import { deregisterIdleTimer } from 'atec-cloud-library-inactivity';
import { AuthenticationService } from '../../services';
import { useRoute } from 'vue-router';
import { QueryKeys } from '../../models';
import { useI18n } from 'vue-i18n';
import { computed, onMounted } from 'vue';
import ModalGenericError from '../../components/common/modal/modal-generic-error.vue';
import BaseBtn from '../../components/common/button/base-btn.vue';

const { t } = useI18n();

const route = useRoute();

async function logout(): Promise<void> {
  deregisterIdleTimer();
  await AuthenticationService.logout();
}

const errorBody = computed(() => {
  let errorArray;
  if (route.query[QueryKeys.MobileError] === 'true') {
    errorArray = [
      t(`global.modal.StatusError.mobile`),
      t(`global.modal.StatusError.text3`),
    ];
  } else if (route.query[QueryKeys.ErrorCode] === '401') {
    errorArray = [t(`global.modal.StatusError.text4`)];
  } else {
    errorArray = [
      t(`global.modal.StatusError.text2`),
      t(`global.modal.StatusError.text3`),
    ];
  }
  return {
    message: errorArray.join(' '),
  };
});

const errorTitle = computed(() => {
  if (route.query[QueryKeys.ErrorCode] === '401') {
    return t(`global.modal.StatusError.text5`);
  }
  return t(`global.modal.StatusError.text1`);
});

onMounted(() => {
  setTimeout(logout, 15000); //15s
});
</script>

<template>
  <ModalGenericError v-if="true" :title="errorTitle" :error="errorBody">
    <template #footer>
      <BaseBtn class="btn-primary" @click="logout">
        {{ $t('global.button.loginPage') }}
      </BaseBtn>
    </template>
  </ModalGenericError>
</template>

<style lang="scss" scoped>
@import '../../assets/css/global.scss';
</style>
