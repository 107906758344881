import { LogEvent, LoggerHook } from 'vue-logger-plugin';

import { LogPayload } from './log-types';

/**
 * Enables to format log data before it is written.
 */
export function getLogFormatter(): LoggerHook {
  return {
    run(event: LogEvent): void {
      event.argumentArray.forEach((logPayload: LogPayload) => {
        logPayload.level = logPayload.level ?? 'INFO';
        logPayload.timestamp = logPayload.timestamp ?? new Date().toISOString();
      });
    },
  };
}
