import fetchIntercept from 'fetch-intercept';
import { useMobileAuthentication } from '../composables/useMobileAuthentication';

export function registerInterceptor() {
  const { authCookie, headers } = useMobileAuthentication();

  fetchIntercept.register({
    request: async (url: string, defaultConfig?: RequestInit) => {
      const updatedOptions = { ...defaultConfig };

      if (authCookie.value) {
        updatedOptions.headers = {
          ...updatedOptions.headers,
          ...headers.value,
        };
      }

      return [url, updatedOptions];
    },

    requestError: (error: any) => {
      return Promise.reject(error);
    },

    response: (response: any) => {
      return response;
    },

    responseError: (error: any) => {
      return Promise.reject(error);
    },
  });
}
