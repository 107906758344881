<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { Toast } from '../../../models/toast';
import { useStore } from '../../../store';
import AlertBlock from '../alert/alert-block.vue';

export default defineComponent({
  name: 'ToastNotification',
  components: { AlertBlock },
  props: {
    toast: {
      type: Object as PropType<Toast>,
      required: true,
      default: () => ({
        id: '0',
        text: 'Sample Toast',
        type: 'success',
        position: 'bottom-right',
        duration: 5000,
      }),
      validator: (toast: Toast): boolean => {
        return (
          ['info', 'success', 'error'].includes(toast.type) &&
          ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(
            toast.position
          )
        );
      },
    },
  },

  data() {
    return {
      store: useStore(),
      show: true,
    };
  },

  mounted() {
    this.setToastPosition();
  },

  created() {
    // Automatically remove the toast after 5 seconds
    setTimeout(() => {
      this.dismissToast();
    }, this.toast.duration);
  },

  methods: {
    dismissToast() {
      this.store.clearToast(this.toast.id);
    },
    setToastPosition() {
      const el: HTMLElement | null = document.querySelector('.toasts-wrapper');
      if (el) {
        const toastsWrapper: HTMLElement = el;
        toastsWrapper.classList.add(this.toast.position);
      }
    },
  },
});
</script>

<template>
  <div class="toast" @click="dismissToast">
    <AlertBlock
      :alert-id="toast.id"
      :show="show"
      :type="toast.type"
      :text="toast.text"
    />
  </div>
</template>

<style lang="scss">
.toast {
  position: relative;
  min-height: 50px;
  width: 100%;
  cursor: pointer;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}
</style>
