import { TierFeatureEnum } from '../models/tier-features';
import { WorkspaceConfig } from '../assets/config';
import { WorkspaceLogger } from '../logger';
import {
  AboutConfig,
  Acquisition,
  BaseInstitution,
  Institution,
  InstitutionResponse,
  NotAGenericError,
  OnlineStatusEnum,
  PaginatedResult,
  Role,
  RoleName,
  SurgeonInstitutionResponse,
  User,
  UserPreferences,
  UserStatus,
} from '../models';

import { UtilsService } from '../services';
import { useStore } from '../store';

const APP_ABOUT_NAMESPACE = WorkspaceConfig.instance().appAboutNamespace();

export async function getAbout(): Promise<AboutConfig | undefined> {
  const destUrl = `${location.origin}/api/v1/about/${APP_ABOUT_NAMESPACE}`;
  const aboutCfg: AboutConfig | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return aboutCfg;
}

export async function getInstitutions(queryParams?: {
  [x: string]: string | number | string[] | string[][];
}): Promise<PaginatedResult | undefined> {
  const destUrl = `${
    location.origin
  }/api/v1/institutions${UtilsService.buildQueryString(queryParams)}`;

  const institutions: PaginatedResult | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return institutions;
}

export async function getInstitutionsArray(queryParams?: {
  [x: string]: string | number | string[] | string[][];
}): Promise<Array<Institution | User | Acquisition>> {
  const store = useStore();
  const institutions: PaginatedResult | undefined = await getInstitutions(
    queryParams
  );

  store.totalInstitutions = institutions?.totalCount ?? 0;
  return institutions!.items ?? [];
}

export async function getInstitution(
  institutionId: string
): Promise<Institution | undefined> {
  const destUrl = `${location.origin}/api/v1/institutions/${institutionId}`;
  const institution: Institution | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return institution;
}

export async function registerInstitution(
  newInstitution: BaseInstitution
): Promise<Institution | void | NotAGenericError | InstitutionResponse> {
  const destUrl = `${location.origin}/api/v1/institutions`;
  const response: Institution | void | NotAGenericError | InstitutionResponse =
    await fetch(destUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newInstitution),
    })
      .then(UtilsService.handleErrors)
      .then((res) => res.json())
      .catch((err) => {
        UtilsService.handleCaughtErrors(err);
        if (err instanceof NotAGenericError) {
          return err;
        }
      });
  return response;
}

export async function updateInstitution(
  updInstitution: Institution
): Promise<boolean | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/institutions/${updInstitution.institutionId}`;
  const status: boolean | void | NotAGenericError = await fetch(destUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updInstitution),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
    });

  return status;
}

export async function updateInstitutionStatus(
  institutionId: string,
  institutionStatus: boolean
): Promise<boolean> {
  const destUrl = `${location.origin}/api/v1/Institutions/${institutionId}/active`;
  const status: boolean | void = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ active: institutionStatus }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return status !== undefined;
}

export async function deleteInstitution(
  institutionId: string
): Promise<boolean> {
  const destUrl = `${location.origin}/api/v1/institutions/${institutionId}`;
  const status: boolean | void = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return status !== undefined;
}

export async function getUsers(queryParams?: {
  [x: string]: string | number | string[] | string[][];
}): Promise<PaginatedResult | undefined> {
  const destUrl =
    `${location.origin}/api/v1/Users` +
    UtilsService.buildQueryString(queryParams);
  const users: PaginatedResult | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return users;
}

export async function getUsersArray(queryParams?: {
  [x: string]: string | number | string[] | string[][];
}): Promise<Array<Institution | User | Acquisition>> {
  const store = useStore();
  const statuses = queryParams!.statuses as Array<string>;
  const users: PaginatedResult | undefined = await getUsers(queryParams);

  if (statuses.includes('Pending')) {
    store.totalPendingUsers = users?.totalCount ?? 0;
  } else {
    store.totalUsers = users?.totalCount ?? 0;
  }
  return users!.items ?? [];
}

export async function getUser(userId: string): Promise<User> {
  const destUrl = `${location.origin}/api/v1/users/${userId}`;
  const user: User = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return user;
}

export async function getCurrentUser(): Promise<User> {
  const destUrl = `${location.origin}/api/v1/users/self`;
  const user: User = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return user;
}

export async function registerUser(
  newUser: User
): Promise<User | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/users`;

  const response: User | void | NotAGenericError = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newUser),
  })
    .then(UtilsService.handleErrors)
    .then((response) => response.json())
    .then((result) => result.data)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err, ['User already exists']);
      if (err instanceof NotAGenericError) {
        return err;
      }
      return JSON.parse(err.message);
    });

  return response;
}

export async function updateRequestUser(
  newUser: User
): Promise<User | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/users/${newUser.userId}`;

  const response: User | void | NotAGenericError = await fetch(destUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newUser),
  })
    .then(UtilsService.handleErrors)
    .then((response) => response.json())
    .then((result) => result.data)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
      return JSON.parse(err.message);
    });

  return response;
}

export async function updateUser(
  updUser: User
): Promise<boolean | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/users/${updUser.userId}`;
  const status: boolean | void | NotAGenericError = await fetch(destUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updUser),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
    });

  return status;
}

export async function updateUsersStatus(
  userIds: string[],
  statusUser: UserStatus
): Promise<boolean> {
  const ids = toUserIdsParams(userIds);
  const destUrl = `${location.origin}/api/v1/Users/Status?${ids}`;
  const status: boolean | void = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: statusUser }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return status !== undefined;
}

export async function resetPassword(
  userIds: string[]
): Promise<void | NotAGenericError> {
  const ids = toUserIdsParams(userIds);
  const destUrl = `${location.origin}/api/v1/users/Status?${ids}`;

  const response: void | NotAGenericError = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: 'Reset' }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
    });

  return response;
}

export async function updateUserPassword(
  userId: string,
  newPassword: string,
  currentPassword: string
): Promise<boolean | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/users/${userId}/password`;
  const status: boolean | void | NotAGenericError = await fetch(destUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      newPassword,
      currentPassword,
    }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw err;
    });
  return status;
}

export async function getUsersConnections(
  userId: string
): Promise<Array<User>> {
  const destUrl = `${location.origin}/api/v1/users/${userId}/Connections`;
  const usersConnections: Array<User> = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return usersConnections;
}
export async function getUsersPreferences(
  userId: string
): Promise<UserPreferences> {
  const destUrl = `${location.origin}/api/v1/UserPreferences?userId=${userId}`;
  const usersPreferences: UserPreferences = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) =>
      UtilsService.handleCaughtErrors(err, [
        'User preferences for user does not exist',
      ])
    );
  return usersPreferences;
}
export async function updateUserPreferences(
  preferencesId: string,
  userId: string,
  email: boolean,
  mobile: boolean,
  completion: boolean,
  scheduling: boolean,
  approval: boolean,
  rod: boolean
): Promise<boolean | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/UserPreferences/${preferencesId}`;
  const status: boolean | void | NotAGenericError = await fetch(destUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId: userId,
      emailNotifications: email,
      mobileNotifications: mobile,
      pendingSurgicalPlanCompletionNotifications: completion,
      pendingSurgicalPlanSchedulingNotifications: scheduling,
      pendingSurgicalPlanApprovalNotifications: approval,
      autoAddRodToSurgicalPlan: rod,
    }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
    });
  return status;
}

export async function setUsersConnection(
  parentId: string,
  childEmail: string
): Promise<boolean | void | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/users/connections`;
  const status: boolean | void | NotAGenericError = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parentUserId: parentId,
      childUserEmail: childEmail,
      favorite: false,
      default: false,
    }),
  })
    .then(UtilsService.handleErrors)
    .then((response) => response.json())
    .then((result) => result.data)
    .catch((err) => {
      if (err instanceof NotAGenericError) {
        return err;
      }
      return JSON.parse(err.message);
    });
  return status;
}

export async function getRoles(): Promise<Array<Role> | undefined> {
  const destUrl =
    `${location.origin}/api/v1/roles?namespace=` + RoleName.DEFAULT_NAMESPACE;
  const roles: Array<Role> | undefined = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));

  return roles;
}

export async function deleteUsers(
  userIds: string[]
): Promise<void | boolean | NotAGenericError> {
  const ids = toUserIdsParams(userIds);
  const destUrl = `${location.origin}/api/v1/users?${ids}`;

  const response: void | boolean | NotAGenericError = await fetch(destUrl, {
    method: 'DELETE',
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
    });
  return response;
}

export async function approveUsers(
  userIds: string[]
): Promise<void | NotAGenericError> {
  const ids = toUserIdsParams(userIds);
  const destUrl = `${location.origin}/api/v1/users/Status?${ids}`;

  const response: void | NotAGenericError = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: 'Active' }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      if (err instanceof NotAGenericError) {
        return err;
      }
    });

  return response;
}

export async function addSurgeonToInstitution(
  institutionId: string,
  surgeon: string
): Promise<string> {
  const destUrl = `${location.origin}/api/v1/Institutions/${institutionId}/Users`;
  let responseText = 'Processing';
  const response: SurgeonInstitutionResponse = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userEmailAddress: surgeon }),
  })
    .then((res) => {
      if (res.ok) {
        responseText = 'Added Successfully';
      }
      UtilsService.handleErrors;
      return res.json();
    })
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
    });
  if (responseText === 'Processing') {
    responseText = response.errorMessage;
  }
  return responseText;
}

export async function getInstitutionConnections(
  institutionId: string
): Promise<Array<User>> {
  const destUrl = `${location.origin}/api/v1/Institutions/${institutionId}/Users`;
  const usersConnections: Array<User> = await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => UtilsService.handleCaughtErrors(err));
  return usersConnections;
}

export async function deleteConnection(
  institutionId: string,
  UserID: string
): Promise<boolean> {
  const destUrl = `${location.origin}/api/v1/Users/${institutionId}/Connections/${UserID}`;
  const response = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => UtilsService.handleCaughtErrors(err));
  if (response == true) {
    return true;
  }
  return false;
}

export async function deleteInstitutionUserConnection(
  institutionId: string,
  UserID: string
): Promise<boolean> {
  const destUrl = `${location.origin}/api/v1/Institutions/${institutionId}/Users/${UserID}`;
  const response = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => UtilsService.handleCaughtErrors(err));
  if (response == true) {
    return true;
  }
  return false;
}

export async function updateUserOnlineStatus(
  onlineStatus: OnlineStatusEnum
): Promise<boolean> {
  const store = useStore();
  const queryParams = {
    userId: store.currentUserInfo.userId,
  };
  const destUrl = `${
    location.origin
  }/api/v1/Users/online-status${UtilsService.buildQueryString(queryParams)}`;
  const response = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ onlineStatus }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new Error(err);
    });
  return response;
}

export async function sendTechnicianHeartbeat(): Promise<boolean> {
  const destUrl = `${location.origin}/api/v1/sessions/technicians`;
  const response = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new Error(err);
    });
  return response;
}

export async function clearTechnicianHeartbeat(): Promise<boolean> {
  const destUrl = `${location.origin}/api/v1/sessions/technicians`;
  const response = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new Error(err);
    });
  return response;
}

export async function getTierFeaturesByInstitutionId(
  institutionId: string
): Promise<TierFeatureEnum[]> {
  const destUrl = `${location.origin}/api/v1/institutions/${institutionId}/tier/features`;
  const response: { features: TierFeatureEnum[] } = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new Error(err);
    });
  return response.features;
}

export async function acknowledgeEULA(
  userId: string
): Promise<boolean | NotAGenericError> {
  const destUrl = `${location.origin}/api/v1/Users/${userId}/eula-acknowledged`;

  const response: boolean | NotAGenericError = await fetch(destUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ eulaAcknowledged: true }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      return false;
    });

  return response;
}

function toUserIdsParams(userIds: string[]) {
  let userIdsParams = '';
  let count = 0;
  userIds.forEach((id) => {
    if (count > 0) {
      userIdsParams += '&';
    }
    userIdsParams += 'userIds=' + id;
    count++;
  });
  return userIdsParams;
}
