export async function inspectlet(user) {
  window.__insp = window.__insp || [];
  __insp.push(['wid', 924344823]);
  const ldinsp = function () {
    if (typeof window.__inspld != 'undefined') return;
    window.__inspld = 1;
    let insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    __insp.push(['identify', user]);
    __insp.push(['tagSession', { email: user }]);
    insp.src =
      ('https:' == document.location.protocol ? 'https' : 'http') +
      '://cdn.inspectlet.com/inspectlet.js?wid=924344823&r=' +
      Math.floor(new Date().getTime() / 3600000);
    let x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);
}
