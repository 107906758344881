import { createPinia } from 'pinia';
import { createApp, markRaw } from 'vue';

import App from './app.vue';
import { i18n } from './assets/i18n';
import { registerInterceptor } from './middleware/fetch-interceptor';
import router from './router';
import { useStore } from './store';
import { registerIdleTimer } from 'atec-cloud-library-inactivity';
import { AuthenticationService } from './services';
import Vue3TouchEvents from 'vue3-touch-events';
import { GesturePlugin } from '@vueuse/gesture';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/themes/aura-light-green/theme.css';
import './assets/css/primevue.css';
import { useMobileAuthentication } from '../src/composables/useMobileAuthentication';
import { useSurgeonFeaturesForNonPhysicianUsers } from '../src/composables/useSurgeonFeaturesForNonPhysicianUsers';
import { useSelectedPhysicianStore } from '../src/store/useSelectedPhysicianStore';
import { useOfflineDetectionStore } from '../src/store/useOfflineDetectionStore';
import { useGlobalErrorsStore } from '../src/store/useGlobalErrorsStore';
import { useResolutionDetectionStore } from '../src/store/useResolutionDetectionStore';
import { Router } from 'vue-router';

// Extends Window interface with properties from server environnement variables
declare global {
  interface Window {
    __env__: { [key: string]: string };
  }
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

async function main(): Promise<void> {
  const pinia = createPinia();
  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });

  registerInterceptor();
  const store = useStore(pinia);
  useSelectedPhysicianStore(pinia);
  useOfflineDetectionStore(pinia);
  useGlobalErrorsStore(pinia);
  useResolutionDetectionStore(pinia);

  const { authCookie, init: initMobileAuth } = useMobileAuthentication();
  initMobileAuth();

  await store.getUserInfo();

  const { initDefaultPhysician } = useSurgeonFeaturesForNonPhysicianUsers();
  await initDefaultPhysician();

  const app = createApp(App);

  if (!authCookie.value) {
    registerIdleTimer(
      AuthenticationService.logout,
      AuthenticationService.refreshSession,
      store.currentUserInfo.inactivitySeconds
    );
  }

  app
    .use(pinia)
    .use(router)
    .use(i18n)
    .use(PrimeVue)
    .use(Vue3TouchEvents, {
      dragFrequency: 10,
      swipeTolerance: 100,
    })
    .use(GesturePlugin)
    .directive('tooltip', Tooltip)
    .mount('#app');
}

main();
