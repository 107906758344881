<script lang="ts" setup>
import { AuthenticationService } from '../../../services';
import SubmenuContainer from './submenu-container.vue';
import SubmenuItem from './submenu-item.vue';
import { deregisterIdleTimer } from 'atec-cloud-library-inactivity';

async function logout(event: Event): Promise<void> {
  event.stopPropagation(); // To prevent triggering global document listener for user session refresh
  deregisterIdleTimer();
  await AuthenticationService.logout();
}
</script>

<template>
  <SubmenuContainer>
    <SubmenuItem @click="logout">
      <img src="../../../assets/img/user-menu-logout.svg" alt="logout" />
      {{ $t('global.logOut') }}
    </SubmenuItem>
  </SubmenuContainer>
</template>
