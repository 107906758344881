<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
  text: string | Array<string>;
  maxLength: number;
  seperator?: string;
}
const props = defineProps<Props>();

const isTextTypeArray = computed(() => {
  return Array.isArray(props.text);
});

function isTextLargerThanMax(str: string | null) {
  if (str === null) {
    return false;
  }
  return str.length > props.maxLength;
}

const isTextInArrayNullAndSeperatorExists = computed(() => {
  return (
    (props.text as Array<string>).some((text) => text === null) &&
    props.seperator
  );
});

function getText(str: string) {
  if (isTextLargerThanMax(str)) {
    const string = str.endsWith(',') ? str.slice(0, -1) : str;
    return string.substring(0, props.maxLength) + '...';
  }
  return str;
}
</script>

<template>
  <div v-if="isTextTypeArray" class="array-condensor">
    <div
      v-for="(string, index) in text"
      :title="isTextLargerThanMax(string) ? (text as Array<string>).join(' ') : ''"
    >
      {{ getText(string) }}
      <span
        v-if="
          index !== text.length - 1 &&
          seperator &&
          !isTextInArrayNullAndSeperatorExists
        "
        >{{ seperator }}</span
      >
    </div>
  </div>
  <div v-else>
    <div :title="isTextLargerThanMax((text as string)) ? (text as string) : ''">
      {{ getText((text as string)) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.array-condensor {
  display: flex;
  gap: 6px;
  row-gap: 2px;
  flex-wrap: wrap;
}

.array-condensor > div {
  display: flex;
}
</style>
