export interface Breadcrumb {
  title: string;
  link: string;
}

export enum QueryKeys {
  BreadCrumbType = 'bctype',
  OpenTabOnLoad = 'tab',
  SalesRepOrClinicalStaffPhysicianId = 'physicianId',
  MobileError = 'mobileError',
  RedirectToPage = 'redirectToPage',
  ErrorCode = 'errorCode',
}
