<script lang="ts" setup>
import ModalPopup from './modal-popup.vue';
import { useGlobalErrorsStore } from '../../../store/useGlobalErrorsStore';
import BaseBtn from '../../../components/common/button/base-btn.vue';
import { useRoute, useRouter } from 'vue-router';
import { ErrorModalButtonEnum } from '../../../models/global-errors';
import { computed } from 'vue';
const router = useRouter();
const route = useRoute();
const globalErrorsStore = useGlobalErrorsStore();

const isMobileRoute = computed(() => {
  return router.currentRoute.value.meta.isMobile;
});

const showErrorModal = computed(() => {
  if (globalErrorsStore.isResolutionError() && isMobileRoute.value) {
    return false;
  }

  if (globalErrorsStore.isResolutionError() && route.path.includes('eula')) {
    return false;
  }

  return globalErrorsStore.errorModalInformation.isError;
});

function handleCancelButtonClicked() {
  globalErrorsStore.clearError();
}
</script>

<template>
  <ModalPopup
    v-if="showErrorModal"
    icon-type="warning"
    :title="globalErrorsStore.errorModalInformation.title"
  >
    <template #body>
      {{ globalErrorsStore.errorModalInformation.text }}
    </template>
    <template #footer>
      <template
        v-for="button in globalErrorsStore.errorModalInformation.buttons"
      >
        <BaseBtn
          v-if="button === ErrorModalButtonEnum.Refresh"
          class="btn-outline"
          style="outline: none"
          @click="router.go(0)"
        >
          {{ $t('global.button.refresh') }}
        </BaseBtn>
        <BaseBtn
          v-if="button === ErrorModalButtonEnum.Cancel"
          class="btn-discrete"
          style="outline: none"
          @click="handleCancelButtonClicked"
        >
          {{ $t('global.button.cancel') }}
        </BaseBtn>
      </template>
      <template
        v-if="globalErrorsStore.errorModalInformation.buttons.length === 0"
      >
        <div></div>
      </template>
    </template>
  </ModalPopup>
</template>
