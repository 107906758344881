<script lang="ts">
import { defineComponent } from 'vue';
import { PatientDetailsToast, Toast } from '../../../models/toast';
import { ToastNotificationService } from '../../../services/toast-notification-service';
import { useStore } from '../../../store';

import ToastNotificationNew from './toast-notification-new.vue';
import ToastNotification from './toast-notification.vue';

export default defineComponent({
  name: 'ToastsContainer',
  components: { ToastNotification, ToastNotificationNew },

  data() {
    return {
      store: useStore(),
      toastNotificationService: new ToastNotificationService(),
    };
  },

  computed: {
    toasts(): Array<Toast> {
      return this.store.toasts;
    },

    isToastsNewStyle(): boolean {
      return this.store.toasts.every(
        (toast: PatientDetailsToast) => toast?.isForPatientDetails === true
      );
    },
  },
});
</script>

<template>
  <Transition>
    <div v-if="isToastsNewStyle && toasts.length" class="toast-container">
      <ToastNotificationNew
        v-for="(toast, index) in toasts"
        :key="index"
        :toast="toast"
        :index="index"
      />
    </div>
    <div v-else-if="toasts.length" class="toasts-wrapper">
      <ToastNotification
        v-for="(toast, index) of toasts"
        :toast="toast"
        :key="index"
      >
      </ToastNotification>
    </div>
  </Transition>
</template>

<style lang="scss">
.toast-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  pointer-events: none;
  padding-bottom: 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
}

.toast-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 380px;
  height: 50px;
  text-align: start;
  padding: 10px;
  pointer-events: all;
  background-color: #e1e3e0;
  border-radius: 4px;
  color: black;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: default;
}

.toasts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 400px;
  min-height: 150px;
  margin: 1rem;
  overflow: hidden;
}

.right-side-toast {
  display: flex;
  gap: 10px;
}

.undo-button {
  color: #026e00;
  font-weight: 400;
  size: 14px;
  cursor: pointer;
}

.bottom {
  bottom: -80vh;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 33px;
  cursor: pointer;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
