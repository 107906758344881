<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import PageInfo from '../../components/common/page-info/page-info.vue';
import Worklist from '../../components/common/worklist/worklist.vue';
import { Breadcrumb } from '../../models';

@Options({
  components: {
    Worklist,
    PageInfo,
  },
})
export default class WorklistView extends Vue {
  get breadcrumbWorklist(): Array<Breadcrumb> {
    return [{ title: this.$t('worklist.listPageTitle'), link: '' }];
  }
}
</script>

<template>
  <PageInfo :breadcrumb="breadcrumbWorklist" />
  <Worklist />
</template>
