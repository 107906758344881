import { createLogger } from 'vue-logger-plugin';

import { getLogFormatter } from './log-formatter';

// create logger with options
const logger = createLogger({
  enabled: true,
  consoleEnabled: true,
  level: 'debug',
  beforeHooks: [getLogFormatter()],
});

export default logger;

export * from './workspace-logger';
export * from './log-formatter';
export * from './log-types';
