<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import ConnectionStatusOffline from '../../../assets/img/connection-status-offline.svg';
import ConnectionStatusOnline from '../../../assets/img/connection-status-online.svg';
import ConnectionStatusPaused from '../../../assets/img/connection-status-paused.svg';
import { OnlineStatusEnum, User } from '../../../models/user';

interface Props {
  userInfo: User;
}
const props = defineProps<Props>();

const userOnlineStatusIcon = computed(() => {
  const onlineStatus = props.userInfo.userRoleProperties?.onlineStatus;
  switch (onlineStatus) {
    case OnlineStatusEnum.Online: {
      return ConnectionStatusOnline;
    }
    case OnlineStatusEnum.Paused: {
      return ConnectionStatusPaused;
    }
    case OnlineStatusEnum.Offline: {
      return ConnectionStatusOffline;
    }
    default: {
      return ConnectionStatusOnline;
    }
  }
});

const isMenuOpen = computed(() => {
  return false;
});
</script>

<template>
  <div class="online-status-container">
    <img
      :src="userOnlineStatusIcon"
      alt="online status"
      class="online-status-icon"
    />
  </div>
</template>

<style lang="scss" scoped>
.online-status-container {
  display: flex;
  gap: 3px;
}
.online-status-icon {
  width: 17px;
  height: 17px;
}

.online-status-dropdown-arrow {
  cursor: pointer;
}
</style>
