<script lang="ts" setup>
import { defineProps, useSlots } from 'vue';
import warningIcon from '../../../assets/img/warning.svg';
import failureIcon from '../../../assets/img/canceled-status.svg';
import successIcon from '../../../assets/img/rod-success.svg';

interface Props {
  iconType?: 'warning' | 'failure' | 'success';
  title?: string;
}

const slots = useSlots();
const props = defineProps<Props>();
</script>

<template>
  <div class="modal-popup-background">
    <div class="modal">
      <div class="modal-header" v-if="slots.header">
        <slot name="header"></slot>
      </div>
      <div class="modal-body">
        <div>
          <img v-if="iconType === 'warning'" :src="warningIcon" alt="warning" />
          <img
            v-else-if="iconType === 'failure'"
            :src="failureIcon"
            alt="failure"
            style="width: 30px; height: 30px"
          />
          <img
            v-else-if="iconType === 'success'"
            :src="successIcon"
            alt="success"
            style="width: 30px; height: 30px"
          />
        </div>
        <h2 v-if="title" data-cy="modal-title">{{ title }}</h2>
        <div class="body">
          <slot name="body"></slot>
        </div>
      </div>
      <div class="modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-popup-background {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal {
  max-width: 480px;
  min-height: 230px;
  background-color: #101e12;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 20px;
  margin-left: 40px;
  margin-right: 40px;
  flex: 1;
}

.modal-header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(219, 219, 219, 0.2);
}

.modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* margin-bottom: 20px; */
}

.modal-footer {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 10px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #c4c7c4;
}

.body {
  text-align: start;
}
</style>
