export enum RoleName {
  DEFAULT_NAMESPACE = 'PORTAL',
  SYSTEM_ADMIN = 'SYSTEM ADMINISTRATOR',
  USER_ADMIN = 'USER ADMINISTRATOR',
  PHYSICIAN = 'PHYSICIAN',
  CLINICAL_STAFF = 'CLINICAL STAFF',
  SALES_REPRESENTATIVE = 'SALES REPRESENTATIVE',
  TECHNICIAN = '3DS TECHNICIAN',
  ROD_MANUFACTURER = 'ROD MANUFACTURER',
}

export enum RoleIdBasedOnRoleName {
  DEFAULT_NAMESPACE = 'PORTAL',
  CLINICAL_STAFF = '07260d7b-adeb-43d1-a283-29f641c0a86e',
  PHYSICIAN = '5c150d05-c0c5-429b-8325-97351159b918',
  ROD_MANUFACTURER = '8e79b7dd-5879-48a6-ab6f-f015d3bd0dec',
  SALES_REPRESENTATIVE = '3a669d32-44eb-47c2-8f53-9ac41a050144',
  SYSTEM_ADMIN = '4b7216a4-6c32-4481-88af-71fe07db08fb',
  TECHNICIAN = '224f3152-2205-478e-8b3e-495d0cac0d07',
  USER_ADMIN = '72b22042-c418-455b-b352-071720609099',
}

export interface Role {
  roleId: string;
  namespace: string;
  name: string;
}
