<script lang="ts" setup>
import { computed, defineEmits } from 'vue';
import { OnlineStatusEnum } from '../../../../models/user';
import '@material/web/checkbox/checkbox';
import BaseBtn from '../../../common/button/base-btn.vue';
import ModalPopup from '../../../common/modal/modal-popup.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  onlineStatus: OnlineStatusEnum | undefined;
}
const props = defineProps<Props>();

const emit = defineEmits(['cancel', 'confirmApprovalAndRelease']);

function handleConfirmSubmission(e: Event) {
  e.stopPropagation();
  emit('confirmApprovalAndRelease');
}

const { t } = useI18n();

const modalBodyText = computed(() => {
  if (props.onlineStatus === OnlineStatusEnum.Offline) {
    return t('userMenu.workflow.statusChangeToOffline');
  } else if (props.onlineStatus === OnlineStatusEnum.Paused) {
    return t('userMenu.workflow.statusChangeToHold');
  } else {
    return '';
  }
});
</script>
<template>
  <ModalPopup
    icon-type="warning"
    :title="$t('userMenu.workflow.changeMyStatus')"
  >
    <template #body>
      {{ modalBodyText }}
    </template>
    <template #footer>
      <BaseBtn class="btn-outline btn-modal btn-white" @click="emit('cancel')">
        {{ $t('global.button.cancelAlt') }}
      </BaseBtn>
      <BaseBtn class="btn-outline btn-modal" @click="handleConfirmSubmission">
        {{ $t('userMenu.workflow.yesChangeMyStatus') }}
      </BaseBtn>
    </template>
  </ModalPopup>
</template>

<style lang="scss" scoped>
.body-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.confirm-and-approve-container {
  display: flex;
  gap: 10px;
}
.btn-modal {
  outline: none;
}
.btn-white {
  color: #f8faf7;
}
</style>
