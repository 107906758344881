import { useStore } from '../store';
import { computed } from 'vue';
import { TierFeatureEnum } from '../models/tier-features';
import { useSurgeonFeaturesForNonPhysicianUsers } from './useSurgeonFeaturesForNonPhysicianUsers';

export function useUserTierPermissions() {
  const tierFeatures = computed(() => {
    const store = useStore();
    const { physicianTierFeatures } = useSurgeonFeaturesForNonPhysicianUsers();
    // return Object.keys(TierFeatureEnum);
    if (
      store.currentUserInfo.tierFeatures !== undefined &&
      store.currentUserInfo.tierFeatures.length > 0
    ) {
      return store.currentUserInfo.tierFeatures;
    } else {
      return physicianTierFeatures.value;
    }
  });

  // patient exams features
  const isPatientFileDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE);
  });
  const isRequestPlanButtonDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_REQUEST_PLAN);
  });
  const isSubmitPlanButtonDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_STRATEGY);
  });
  const isSurgicalStrategyTabDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_STRATEGY);
  });
  const isPatientTrendsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_TRENDS);
  });
  // patient exams, actual exam type features
  const isPatientFileEosExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_EOS);
  });
  const isPatientFileAlignmentExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_ALIGNMENT);
  });
  const isPatientFileMobileExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_MOBILE);
  });
  const isPatientFileIoaExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_IOA);
  });
  const isPatientFileAlignmentReportsExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_ALIGNMENT_REPORTS);
  });
  const isPatientFilePlanningReportsExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_PLANNING_REPORTS);
  });
  const isPatientFileSurgicalPlanExamsDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_SURGICAL_PLAN);
  });

  // patient bar features
  const isSurgicalStrategyNotAvailable = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.PATIENT_FILE_STRATEGY);
  });

  // case calendar features
  const isCaseCalendarSurgeryPlanOverflowDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.CALENDAR_PLAN);
  });

  // navigation bar / router features
  const isActionsMenuBarDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.ACTIONS);
  });

  const isPatientsMenuBarDisabled = computed(() => {
    // this is if a surgeon is not connected to a physician but needs access to
    // at least patient list
    if (tierFeatures.value === undefined) {
      return false;
    }
    return isFeatureDisabled(TierFeatureEnum.PATIENTS);
  });

  const isAnalyticsMenuBarDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.ANALYTICS);
  });

  const isCalendarMenuBarDisabled = computed(() => {
    return isFeatureDisabled(TierFeatureEnum.CALENDAR);
  });

  function isFeatureDisabled(feature: TierFeatureEnum) {
    if (!tierFeatures.value) {
      return true;
    }
    return !tierFeatures.value.includes(feature);
  }

  return {
    // patient exams features
    isPatientFileDisabled,
    isRequestPlanButtonDisabled,
    isSubmitPlanButtonDisabled,
    isSurgicalStrategyTabDisabled,
    isPatientTrendsDisabled,
    // patient exams exams type features
    isPatientFileEosExamsDisabled,
    isPatientFileAlignmentExamsDisabled,
    isPatientFileMobileExamsDisabled,
    isPatientFileIoaExamsDisabled,
    isPatientFileAlignmentReportsExamsDisabled,
    isPatientFilePlanningReportsExamsDisabled,
    isPatientFileSurgicalPlanExamsDisabled,

    // patient bar features
    isSurgicalStrategyNotAvailable,

    // case calendar features
    isCaseCalendarSurgeryPlanOverflowDisabled,

    // navigation bar / router features
    isActionsMenuBarDisabled,
    isPatientsMenuBarDisabled,
    isCalendarMenuBarDisabled,
    isAnalyticsMenuBarDisabled,
  };
}
