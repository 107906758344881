/**
 * Enumerates error messages obtained in response to calls to http servers api.
 */
export enum HttpError {
  DUPLICATE_INSTITUTION = 'DUPLICATE_INSTITUTION',
  DUPLICATE_USER = 'DUPLICATE_USER',
  USER_UNAUTHORIZED = 'USER_UNAUTHORIZED',
}

/**
 * Enables to differentiate generic errors from ones which should trigger a specific behavior.
 */
export class NotAGenericError extends Error {
  /**
   * Builds object.
   *
   * @param message Error message.
   */
  constructor(message: string) {
    super(message);
  }
}

export class ErrorModal {
  show: boolean;
  errorResponse: any;
  constructor() {
    this.show = false;
    this.errorResponse = {};
  }

  showModal(error?: any) {
    console.log(error);
    this.show = true;
    if (error) this.setError(error);
  }

  setError(error: any) {
    this.errorResponse = error;
  }

  get error() {
    return this.errorResponse;
  }
}

export class ErrorWithStatus extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}
