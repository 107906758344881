import { defineStore } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

export const useResolutionDetectionStore = defineStore(
  'resolutionDetection',
  () => {
    const { width, height } = useWindowSize();

    const WINDOW_MIN_WIDTH = 1174; // 1175 but because <= is used, 1174 is used
    const WINDOW_MIN_HEIGHT = 600;

    const TABLET_MIN_WIDTH = 1278;
    const TABLET_MIN_HEIGHT = 768;

    const isResolutionTooLow = computed(() => {
      if (
        _isViewportResLowerThanOrEqualTo(WINDOW_MIN_WIDTH, WINDOW_MIN_HEIGHT)
      ) {
        return true;
      }
      return false;
    });

    const isDisplayResolutionTabletSize = computed(() => {
      if (_isViewportResLowerThanOrEqualTo(TABLET_MIN_WIDTH, 0)) {
        return true;
      }
      return false;
    });

    function _isViewportResLowerThanOrEqualTo(w: number, h: number) {
      return width.value <= w || height.value <= h;
    }

    return {
      isResolutionTooLow,
      isDisplayResolutionTabletSize,
    };
  }
);
