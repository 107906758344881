import { WorkspaceLogger } from '../../logger';
import { ConfigType, EnvType } from './config-types';

/**
 * Enables to override the properties from an inpout config file.
 */
export class ConfigurationOverrider {
  /**
   * Returns the modified config whose values may have been overriden from an input `env` object.
   * Compared to `config` Each property inside `env` must be formatted such as `key_nestedKey_nestedKeyOfnestedKey:value`.
   * ```
   * Example:
   * const defaultConfig = {
   *   app: {
   *     name: 'UniTest',
   *     version: '0.0.1',
   *   },
   * };
   * const env = {
   *   app_version: '1.0.0',
   * };
   * aConfig = aConfigOverrider.overrideProperties(env, aConfig); // aConfig.app.version will will equal to env.app_version
   * ```
   *
   * @param env Represents an object which may contain overriden values of some properties from `config`.
   * @param config Provides input config object.
   * @param prefix Enables to handle nested properties (their name will be a concatenation of previous properties keys).
   */
  overrideProperties(
    env: EnvType,
    config: ConfigType,
    prefix?: string
  ): ConfigType {
    for (const [key, value] of Object.entries(config)) {
      const name = prefix === undefined ? key : `${prefix}_${key}`;

      // Object.getOwnPropertyDescriptor() enables to get if the `env` object overrides a property
      if (typeof value === 'number' || typeof value === 'string') {
        if (Object.getOwnPropertyDescriptor(env, name)) {
          const newValue = this.overrideValue(env, name, value);
          config[key] = newValue;
        }
      } else {
        if (Array.isArray(value)) {
          if (Object.getOwnPropertyDescriptor(env, name)) {
            const envVar = env[name];
            try {
              const newValue = JSON.parse(envVar) as
                | Array<string>
                | Array<number>;
              if (Array.isArray(newValue)) {
                config[key] = newValue;
              } else {
                WorkspaceLogger.instance().log({
                  message: `ConfigurationOverrider - Cannot convert ${name} to array`,
                  level: 'ERROR',
                });
              }
            } catch (err) {
              WorkspaceLogger.instance().log({
                message: `ConfigurationOverrider - Cannot parse '${envVar}' environment variable!`,
                level: 'ERROR',
              });
            }
          }
        } else {
          config[key] = this.overrideProperties(env, value, name);
        }
      }
    }
    return config;
  }

  /**
   * Returns the value extracted from an input env object, from a property identified with `key`.
   *
   * @param env Represents an object which may contain an overriden value of the property identified with `key`.
   * @param key Key of a property.
   * @param value Value of a property.
   */
  private overrideValue(
    env: EnvType,
    key: string,
    value: string | number
  ): string | number {
    let newValue = value;
    const stringValue = env[key];

    // If value from ENV variable is string type, just override
    switch (typeof value) {
      case 'string':
        newValue = stringValue;
        break;
      case 'number':
        newValue = this.handleNumberInput(value, stringValue);
        break;
      default:
        break;
    }
    return newValue;
  }

  /**
   * Parses string value and returns a number with correct type.
   *
   * @param originalValue Original value which type will be retrieved.
   * @param outputValue Value to be parsed to a number.
   */
  private handleNumberInput(
    originalValue: number,
    outputValue: string
  ): number {
    return Number.isInteger(originalValue)
      ? parseInt(outputValue, 10)
      : parseFloat(outputValue);
  }
}
