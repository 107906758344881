<script lang="ts">
/* eslint-disable */
import { debounce } from 'throttle-debounce';
import { Options, Vue } from 'vue-class-component';

import { WorkspaceConfig } from '../../../assets/config';
import { Acquisition, Application, EXAMS_DEFAULT_LIMIT } from '../../../models';
import {
  DateFormatting,
  PatientListService,
  WebsocketClient,
} from '../../../services';
import { useStore } from '../../../store';
import Loader from '../loader/loader.vue';
import DicomImporter from '../dicom-importer/dicom-importer.vue';
import ExamLine from '../../exam/exam-line.vue';

@Options({
  components: {
    DicomImporter,
    Loader,
    ExamLine,
  },
})
export default class Worklist extends Vue {
  store = useStore();
  WorkspaceConfig = WorkspaceConfig;

  readonly debounceTime = 300;
  private currentPage = 1;
  hasNewElement = false;
  refreshing = false;
  showGoTop = false;
  showLoader = false;
  DateFormatting = DateFormatting;
  searchWorklist = '';
  websocketClient: WebSocket | undefined;
  hidePanelDicomImporter = true;

  async created(): Promise<void> {
    await this.getExamsData();
    await this.getApplicationsData();
    this.websocketClient = WebsocketClient.connectWebsocket(
      {
        url: WebsocketClient.buildWebsocketServerUrl(
          location.protocol,
          location.host,
          'patient-list'
        ),
      },
      {
        message: (event: MessageEvent): void => {
          const receivedEvent = JSON.parse(event.data);
          if (
            PatientListService.listenedWebsocketStatus.includes(
              receivedEvent?.status
            )
          ) {
            this.hasNewElement = true;
          }
        },
      }
    );
  }

  checkCloseModal(event: Event): void {
    this.hidePanelDicomImporter =
      event
        .composedPath()
        .find(
          (e: EventTarget) => (e as HTMLElement).id === 'worklist-dicom-import'
        ) === undefined;
  }

  unmounted(): void {
    this.clearWorklist(true);
  }

  private clearWorklist(closeWebsocket?: boolean) {
    this.store.resetExams();
    if (closeWebsocket) {
      this.websocketClient?.close();
    }
  }

  async getExamsData(): Promise<void> {
    this.showLoader = true;
    let queryParams;
    if (this.searchWorklist.length > 0) {
      const preparedQueryParams = this.prepareSearchQuery(this.searchWorklist);
      queryParams = {
        page: this.currentPage,
        limit: EXAMS_DEFAULT_LIMIT,
        patientName: preparedQueryParams,
        patientID: preparedQueryParams,
      };
    } else {
      queryParams = {
        page: this.currentPage,
        limit: EXAMS_DEFAULT_LIMIT,
      };
    }
    await this.store.getExams(queryParams);
    this.showLoader = false;
  }

  async getApplicationsData(): Promise<void> {
    await this.store.getApplications();
  }

  get exams(): Array<Acquisition> {
    return this.store.exams;
    //TEMPORARILY
    //return additionalAcquisitionListStub.concat(this.store.exams);
  }

  get totalExams(): number {
    return this.store.totalExams;
    //TEMPORARILY
    //return this.store.totalExams + additionalAcquisitionListStub.length;
  }

  get application(): Application | undefined {
    return (
      this.store.applications.filter((app: Application) => app.isDefault)[0] ??
      this.store.applications[0]
    );
  }

  async refreshExamsList(): Promise<void> {
    if (!this.refreshing) {
      this.refreshing = true;
      this.hasNewElement = false;
      this.clearWorklist();
      this.currentPage = 1;
      await this.getExamsData();
      this.refreshing = false;
    }
  }

  goTopTable(): void {
    const firstLine = document.querySelector("[name='exam-0']") as HTMLElement;
    firstLine.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  prepareSearchQuery(aSearchQueryString: string): string {
    return aSearchQueryString
      .split(',')
      .filter((aQueryPart) => aQueryPart.length > 0)
      .map((aQueryPart) => aQueryPart.trim())
      .join(',');
  }

  searchExamList = debounce(this.debounceTime, () => {
    this.currentPage = 1;
    this.getExamsData();
  });

  async loadExamsIfNeeded(event: Event): Promise<void> {
    const { scrollTop, offsetHeight, scrollHeight } =
      event.target as HTMLInputElement;
    // 5% margin
    const margedScrollHeight = scrollHeight * 0.95;
    if (scrollTop + offsetHeight >= margedScrollHeight) {
      if (
        this.store.exams.length < this.store.totalExams &&
        this.currentPage <=
          Math.ceil(this.store.totalExams / EXAMS_DEFAULT_LIMIT)
      ) {
        this.currentPage++;
        await this.getExamsData();
      }
    }
    this.showGoTop = scrollTop >= scrollHeight * 0.3;
  }
}
</script>

<template>
  <div id="worklist" class="worklist" @click="checkCloseModal">
    <div class="worklist-header">
      <div class="container-flex">
        <div class="item-flex">
          <div style="height: 1.2em"></div>
          <button
            id="worklist-refresh"
            :class="{
              active: hasNewElement,
              filter: true,
              refresh: true,
              rotate: true,
            }"
            @click="refreshExamsList"
          >
            <div class="symbol">
              <img src="../../../assets/img/refresh.svg" alt="refresh" />
            </div>
            <div class="text">
              <span style="right: 0" class="vertical-center">{{
                $t('worklist.refresh')
              }}</span>
            </div>
            <div
              :class="{
                alertcontainer: true,
                active: hasNewElement,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                width="40"
                id="alert-refresh"
                viewBox="-2 -2 70 70"
                v-show="hasNewElement"
              >
                <path
                  d="M17.833 24.417V7.25h4.334v17.167Zm0 8.333v-4.375h4.334v4.375Z"
                />
              </svg>
            </div>
          </button>
        </div>
        <div class="item-flex" style="margin-right: 40px">
          <div class="flex header" style="padding-top: 5px">
            <img
              class="icon left-icon"
              src="../../../assets/img/search.svg"
              alt="search"
            />
            <input
              type="search"
              id="worklist-search"
              :placeholder="$t('worklist.searchPlaceholder')"
              v-model="searchWorklist"
              class="filter search"
              autocomplete="off"
              @keyup="searchExamList()"
            />
            <img
              src="../../../assets/img/cross-in-circle.svg"
              id="worklist-search-clear"
              alt="error"
              v-show="searchWorklist"
              class="icon right-icon"
              @click="
                searchWorklist = '';
                getExamsData();
              "
            />
          </div>
        </div>
        <div
          v-if="WorkspaceConfig.instance().isDevMode()"
          class="item-flex"
          id="worklist-dicom-import"
        >
          <DicomImporter :hide-panel="hidePanelDicomImporter" />
        </div>
      </div>
    </div>
    <div v-if="exams && exams.length > 0" class="worklist-table-header">
      {{ totalExams }} {{ $tc('worklist.exam', totalExams) }}
    </div>
    <div
      class="worklist-table responsive-table-height"
      @scroll="loadExamsIfNeeded"
    >
      <table aria-describedby="patient list">
        <thead v-show="false">
          <!-- avoid an error SonarQube -->
          <tr>
            <th scope="col">{{ $t('worklist.patientList.familyName') }}</th>
            <th scope="col">{{ $t('worklist.patientList.firstName') }}</th>
            <th scope="col">
              {{ $t('worklist.patientList.birthDateAndSex') }}
            </th>
            <th scope="col">
              {{ $t('worklist.patientList.accessionNumber') }}
            </th>
            <th scope="col">{{ $t('worklist.patientList.separator') }}</th>
            <th scope="col">
              {{ $t('worklist.patientList.acquisitionsDate') }}
            </th>
            <th scope="col">{{ $t('worklist.patientList.description') }}</th>
            <th scope="col">{{ $t('worklist.patientList.status') }}</th>
          </tr>
        </thead>
        <tbody>
          <ExamLine
            v-for="(wexam, index) of exams"
            :key="index"
            :exam="wexam"
            :index="index"
            :application="application"
          />
        </tbody>
        <tfoot>
          <tr v-show="showLoader" style="background: transparent">
            <td colspan="8">
              <Loader :show-loader="showLoader" />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      v-show="exams && exams.length === 0 && !showLoader && !searchWorklist"
      id="worklist-empty"
      class="emptyList"
    >
      {{ $t('worklist.emptyList') }}
    </div>
    <div
      v-show="exams && exams.length === 0 && !showLoader && searchWorklist"
      id="worklist-empty-search"
      class="emptyList emptySearch"
    >
      {{ totalExams }} {{ $tc('worklist.exam', totalExams) }}
      <div class="emptySearchContainer">
        <div class="svgContainer">
          <img src="../../../assets/img/not-found.svg" alt="not-found" />
        </div>
        <div class="textContainer">
          <p>
            {{ $t('worklist.emptySearchList.yourSearch')
            }}<span class="searchedText">{{ searchWorklist }}</span
            >{{ $t('worklist.emptySearchList.noMatch') }}
          </p>
          <p>
            {{ $t('worklist.emptySearchList.suggestion1') }}<br />
            <span class="bold">{{
              $t('worklist.emptySearchList.suggestion2')
            }}</span
            ><br />
            {{ $t('worklist.emptySearchList.suggestion3') }}<br />
            {{ $t('worklist.emptySearchList.suggestion4') }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-show="showGoTop" class="goTop" @click="goTopTable">
    <div class="buttonGoTop"></div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/css/global.scss';

.worklist {
  margin: 0 5vw;
  margin-left: calc(5vw + $navBarWidth);
  font-size: 1.2em;
}
.emptyList.emptySearch {
  text-align: left;
  .emptySearchContainer {
    width: 550px;
    margin: 30px auto 0 auto;
    .searchedText {
      font-weight: bold;
    }
    div.svgContainer {
      float: left;
      width: 130px;
      margin-top: 15px;
    }
    div.textContainer {
      float: right;
    }
  }
}
.bold {
  font-weight: bold;
}
.container-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-start;
}
.item-flex {
  flex: 0 0 auto;
}

.worklist-header {
  text-align: justify;
  .icon {
    position: relative;
    align-self: center;
    z-index: 3;
  }
  .left-icon {
    position: absolute;
    left: calc(2em + 10px);
  }
  .right-icon {
    right: 40px;
    cursor: pointer;
  }
  .filter {
    height: 42px;
  }
  .search {
    width: 410px;
    margin: 0 2em;
    padding-left: 50px;
    padding-right: 40px;
  }

  .refresh {
    background-color: $darkgreytwo;
    border: none;
    border-radius: 6px;
    font-size: 1em;
    display: flex;
    width: 150px;
    &:hover {
      transition: all 0.5s ease;
      color: $white;
      background-color: #656764;
      cursor: pointer;
      svg {
        fill: $white;
      }
    }
    &.active {
      background-color: $darkorange;
      &:hover {
        background-color: #be6c15;
      }
    }
    & .alertcontainer {
      background: transparent;
      border-radius: 20px;
      height: 25px;
      width: 25px;
      position: relative;
      top: -10px;
      right: -10px;
    }
    & .alertcontainer.active {
      background: $darkblue;
      border-radius: 20px;
      height: 25px;
      width: 25px;
      position: relative;
      top: -10px;
      right: -10px;
      & #alert-refresh {
        fill: $white;
        position: relative;
      }
    }

    .symbol {
      flex: 1;
    }
    .text {
      flex: 2;
      position: relative;
      height: 100%;
    }
  }
}

.flex {
  display: flex;
  position: relative;
}

.worklist-table-header {
  text-align: start;
  padding: 1rem 0;
}

.worklist-table {
  text-align: justify;
  overflow-y: scroll;
  table {
    position: relative;
    margin: 0 auto;
    width: 100%;
    border-spacing: 0 10px;
    padding-right: 0;
    margin-bottom: 10px;
    tr {
      padding: 10px 10px;
      background-color: $darkgrey;
      border: none;
      border-radius: 4px;
      width: 100%;
    }
  }
}

.goTop {
  width: 5vw;
}

@media all and (max-width: 1040px) {
  .goTop {
    width: 6vw;
  }
}

.rotate:hover img {
  transition: transform 1s;
  transition-delay: 0.1s;
  transform: rotate(-180deg);
}
</style>
