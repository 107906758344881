import { PatientDetailsToast } from '../../src/models';
import { useStore } from '../store';

export class ToastNotificationService {
  store: any;

  constructor() {
    this.store = useStore();
  }

  push(toast: PatientDetailsToast) {
    this.store.toasts.push(toast);
  }

  pushDefaultMessage(text: string) {
    const msg: PatientDetailsToast = {
      text,
      duration: 5000,
      isForPatientDetails: true,
      id: '',
      type: '',
      position: '',
    };
    this.push(msg);
  }

  pushDefaultMessageWithCallback(text: string, callback: CallableFunction) {
    const msg: PatientDetailsToast = {
      text,
      duration: 4000,
      isForPatientDetails: true,
      id: '',
      type: '',
      position: '',
      callableFunction: callback,
    };
    this.push(msg);
  }

  remove(index: number) {
    this.store.toasts.splice(index, 1);
  }
}
