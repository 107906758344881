<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component';

import { Breadcrumb, User } from '../../../models';
import { UtilsService } from '../../../services';
import { useStore } from '../../../store';
import UserMenu from '../user-menu/user-menu.vue';
import ArrowIcon from '../../../assets/img/alert-error.svg';

class Props {
  breadcrumb = prop<Array<Breadcrumb>>({
    required: false,
    default: [],
  });
}

@Options({
  components: {
    UserMenu,
    ArrowIcon,
  },
})
export default class PageInfo extends Vue.with(Props) {
  UtilsService = UtilsService;
  store = useStore();
  showUserMenu = false;

  get user(): User {
    return this.store.currentUserInfo;
  }
}
</script>

<template>
  <div class="page-info-panel" id="page-info-panel">
    <div class="header">
      <div class="page flex-child" id="page-info-panel-left">
        <div
          v-for="(prop, index) in breadcrumb"
          :key="`pageInfoLink-${index}`"
          :id="`page-info-panel-nav-${index}`"
        >
          <span
            :id="`page-info-panel-nav-title-${index}`"
            v-if="prop.link === ''"
          >
            {{ prop.title }}
          </span>
          <router-link
            :id="`page-info-panel-nav-link-${index}`"
            v-else
            :to="prop.link"
          >
            {{ prop.title }}&nbsp;>&nbsp;
          </router-link>
        </div>
      </div>
      <div class="user flex-child" id="page-info-panel-right">
        <router-link
          to="/profile"
          style="padding-left: 10px"
          id="page-info-panel-userprofile-link"
          >{{
            `${UtilsService.getUserFullName(user)} ${user.suffix ?? ''}`
          }}</router-link
        >
        <img
          src="../../../assets//img//chevron_down.svg"
          class="menu-toggle"
          alt="down arrow"
          :style="showUserMenu ? 'transform: rotate(180deg)' : ''"
          @click="showUserMenu = !showUserMenu"
        />
        <UserMenu v-model="showUserMenu" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.page-info-panel {
  position: relative;
  margin-left: $navBarWidth;
  margin-top: 1em;
  margin-bottom: 1em;
  z-index: 3;
  a {
    text-decoration: none;
    color: inherit;
  }
  .header {
    padding: 10px 50px;
    font-size: 1.3em;
    display: flex;
    .flex-child {
      flex: 1;
    }
    .page {
      margin-left: 3rem;
      text-align: start;
      display: inline-flex;
    }
    .user {
      text-align: end;
      margin-right: 3rem;
    }
  }
}

.menu-toggle {
  margin-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
}
</style>
