type StepBetweenDate =
  | '<5min'
  | '<10min'
  | '<15min'
  | '<30min'
  | '<1h'
  | '<6h'
  | '<24h'
  | '>1d';

export function getStepBetweenTwoDates(
  targetDate: string,
  today: Date = new Date()
): StepBetweenDate {
  const diffInMinutes = Math.abs(
    Math.ceil((today.getTime() - new Date(targetDate).getTime()) / (1000 * 60))
  );
  switch (true) {
    case diffInMinutes < 5:
      return '<5min';
    case diffInMinutes < 10:
      return '<10min';
    case diffInMinutes < 15:
      return '<15min';
    case diffInMinutes < 30:
      return '<30min';
    case diffInMinutes < 60:
      return '<1h';
    case diffInMinutes < 360:
      return '<6h';
    case diffInMinutes < 1440:
      return '<24h';
    default:
      return '>1d';
  }
}

export function getAgeInYear(value: string, today: Date = new Date()): number {
  if (!value) return 0;
  const birthDate = formatDateFromDicom(value);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function formatDateToString(
  value: string | undefined,
  displayLanguage: string = navigator.language
): string {
  if (!value) return '';
  if (value.length !== 8) {
    return value;
  }
  const date = formatDateFromDicom(value);
  return date.toLocaleDateString(displayLanguage);
}

export function formatDateFromDicom(value: string): Date {
  const year = parseInt(value.substring(0, 4));
  const month = parseInt(value.substring(4, 6));
  const day = parseInt(value.substring(6, 8));
  const date = new Date(year, month - 1, day);
  return date;
}

export function examDateFormatter(date: Date): string {
  const monthNames: Array<string> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
}

export function surgicalPlanDateFormatter(dateString: string): string {
  const date = new Date(dateString);

  const shortDate = new Intl.DateTimeFormat('en', { dateStyle: 'short' });
  const shortTime = new Intl.DateTimeFormat('en', { timeStyle: 'short' });
  const dateTime = `${shortDate.format(date)} ${shortTime.format(date)}`;

  return dateTime;
}

export function calculateAgeFromBirthdate(birthdate: string) {
  const birthDate = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function isDateWithin15Days(date: string | undefined): boolean {
  if (date) {
    const singleDayAsTime = 24 * 60 * 60 * 1000;
    const today = new Date().getTime();
    const scheduledDate = new Date(date);
    const leadTime = today + singleDayAsTime * 15;
    return scheduledDate.getTime() < leadTime;
  }
  return false;
}

// https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
export function timeSince(time: any) {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  const time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds == 0) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == 'string') return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  return time;
}
