<script lang="ts" setup>
import { User } from '../../../models';
import SubmenuContainer from './submenu-container.vue';
import SubmenuItem from './submenu-item.vue';
import { computed } from 'vue';

interface Props {
  surgeons: Array<User> | undefined;
  currentPhysicianIdViewing: string | null;
}
const props = defineProps<Props>();
const emit = defineEmits(['surgeonClicked']);

const currentlyViewedPhysician = computed(() => {
  return props.surgeons?.find(
    (surgeon) => surgeon.userId === props.currentPhysicianIdViewing
  );
});

function handleSurgeonClicked(user: User) {
  emit('surgeonClicked', user);
}
</script>

<template>
  <SubmenuContainer title="Surgeons">
    <!-- so top user is currently viewed user -->
    <SubmenuItem
      v-if="currentlyViewedPhysician"
      style="cursor: pointer"
      @click="handleSurgeonClicked(currentlyViewedPhysician)"
    >
      <div class="sub-menu-connections-container">
        <div class="name-title">
          {{ currentlyViewedPhysician.firstName }}
          {{ currentlyViewedPhysician.lastName }}
        </div>
        <div class="institution-title">
          {{ currentlyViewedPhysician.institutionName }}
        </div>
      </div>
    </SubmenuItem>
    <template v-for="user in surgeons">
      <SubmenuItem
        v-if="user.userId !== currentPhysicianIdViewing"
        style="cursor: pointer"
        @click="handleSurgeonClicked(user)"
      >
        <div class="sub-menu-connections-container">
          <div class="name-title">{{ user.firstName }} {{ user.lastName }}</div>
          <div class="institution-title">{{ user.institutionName }}</div>
        </div>
      </SubmenuItem>
    </template>
    <div style="font-size: 12px" v-if="surgeons?.length === 0">
      {{ $t('userMenu.noSurgeonsAvailable') }}
    </div>
  </SubmenuContainer>
</template>

<style lang="scss" scoped>
.sub-menu-connections-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.institution-title {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #c4c7c4;
}

.selected {
  border-right: 5px solid #43483f;
}

.name-title {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
</style>
