<script lang="ts">
import { Vue, prop } from 'vue-class-component';
import {
  Acquisition,
  Application,
  Modeling,
  ModelingState,
} from '../../models';
import { ApplicationLauncherService, DateFormatting } from '../../services';

class Props {
  exam = prop<Acquisition>({
    required: true,
  });
  index = prop({
    type: Number,
    required: true,
  });
  application = prop<Application>({
    required: false,
  });
}

export default class ExamLine extends Vue.with(Props) {
  DateFormatting = DateFormatting;

  async goToApp(exam: Acquisition): Promise<void> {
    const latestModeling = this.getLatestModeling(exam);
    if (
      this.application &&
      latestModeling &&
      this.getModelingState(latestModeling) === 'success'
    ) {
      await this.getLaunchUrl(this.application.id, {
        modeling: latestModeling.id,
      });
    }
  }

  getLatestModeling(acquisition: Acquisition): Modeling | undefined {
    const sorted = acquisition.modelings?.sort((mod1, mod2) => {
      return (
        Number.parseInt(mod1.creationTimestamp, 10) -
        Number.parseInt(mod2.creationTimestamp, 10)
      );
    });
    return sorted?.length > 0 ? sorted[sorted.length - 1] : undefined;
  }

  getModelingState(modeling: Modeling | undefined): ModelingState {
    let state: ModelingState = 'success';
    if (modeling === undefined || modeling.state.includes('NOT_MODELIZED')) {
      state = 'failure';
    } else if (modeling.state.includes('IA_IN_PROGRESS')) {
      state = 'in progress';
    }
    return state;
  }

  async getLaunchUrl(
    appId?: string,
    queryParams?: {
      [x: string]: string;
    }
  ): Promise<void> {
    if (appId !== undefined) {
      const urlAppLaunch = await ApplicationLauncherService.getLaunch(
        appId,
        queryParams
      );
      if (urlAppLaunch) {
        this.$router.push({
          name: 'app',
          params: {
            url: urlAppLaunch.replace(/{{ origin }}/, location.origin),
          },
        });
      }
    }
  }
}
</script>

<template>
  <tr
    :name="`exam-${index}`"
    :id="`exam-${exam.id}`"
    :class="`${getModelingState(getLatestModeling(exam))}`"
    @click="goToApp(exam)"
  >
    <td id="exam-line-familyName">
      <div class="tooltip">
        <span class="ellipsetext">{{
          exam.series.study.patient.name?.familyName?.toUpperCase()
        }}</span>
        <span class="tooltiptext">{{
          exam.series.study.patient.name?.familyName?.toUpperCase()
        }}</span>
      </div>
    </td>
    <td id="exam-line-firstName">
      <div class="tooltip">
        <span class="ellipsetext">{{
          exam.series.study.patient.name?.firstName
        }}</span>
        <span class="tooltiptext">{{
          exam.series.study.patient.name?.firstName
        }}</span>
      </div>
    </td>
    <td style="min-width: 47px" id="exam-line-ageSex">
      <span v-if="exam.series.study.patient.birthDate">{{
        DateFormatting.getAgeInYear(exam.series.study.patient.birthDate)
      }}</span>
      <span v-else>n/a</span>
      &nbsp; - &nbsp;
      {{ exam.series.study.patient.sex }}
    </td>
    <td id="exam-line-patientId">
      {{ exam.series.study.patient.patientID }}
    </td>
    <td><div class="vertical-separator vertical-center"></div></td>
    <td id="exam-line-dateExam">
      {{ DateFormatting.formatDateToString(exam.series.study.date) }}
    </td>
    <td style="max-width: 350px" id="exam-line-description">
      {{ exam.series.study.description ?? exam.series.description }}
    </td>
    <td id="exam-line-state">
      <ul class="overlay">
        <li>
          <svg
            v-show="getModelingState(getLatestModeling(exam)) === 'success'"
            xmlns="http://www.w3.org/2000/svg"
            height="30px"
            viewBox="0 0 24 24"
            width="30px"
            fill="#A9E5BB"
            id="svg-active"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
            />
          </svg>
          <svg
            v-show="getModelingState(getLatestModeling(exam)) === 'in progress'"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="#EEC584"
            id="svg-in-progress"
          >
            <path
              d="M15.3 16.7 16.7 15.3 13 11.6V7H11V12.4ZM12 22Q9.925 22 8.1 21.212Q6.275 20.425 4.925 19.075Q3.575 17.725 2.788 15.9Q2 14.075 2 12Q2 9.925 2.788 8.1Q3.575 6.275 4.925 4.925Q6.275 3.575 8.1 2.787Q9.925 2 12 2Q14.075 2 15.9 2.787Q17.725 3.575 19.075 4.925Q20.425 6.275 21.212 8.1Q22 9.925 22 12Q22 14.075 21.212 15.9Q20.425 17.725 19.075 19.075Q17.725 20.425 15.9 21.212Q14.075 22 12 22ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM12 20Q15.325 20 17.663 17.663Q20 15.325 20 12Q20 8.675 17.663 6.337Q15.325 4 12 4Q8.675 4 6.338 6.337Q4 8.675 4 12Q4 15.325 6.338 17.663Q8.675 20 12 20Z"
            />
          </svg>
          <svg
            v-show="getModelingState(getLatestModeling(exam)) === 'failure'"
            xmlns="http://www.w3.org/2000/svg"
            height="30px"
            viewBox="0 0 24 24"
            width="30px"
            fill="#FA8E8E"
            id="svg-failure"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
            />
          </svg>
        </li>
        <li class="step-date">
          <span
            v-show="getModelingState(getLatestModeling(exam)) === 'in progress'"
          >
            {{
              DateFormatting.getStepBetweenTwoDates(
                getLatestModeling(exam)?.creationTimestamp ?? ''
              )
            }}
          </span>
        </li>
      </ul>
    </td>
  </tr>
</template>

<style lang="scss" scoped>
@import '../../assets/css/global.scss';

tr {
  padding: 10px 10px;
  background-color: $darkgrey;
  border: none;
  border-radius: 4px;
  width: 100%;
}
.success {
  &:hover {
    transition: all 0.5s ease;
    background-color: $darkgreytwo;
    cursor: pointer;
  }
}
td {
  height: 30px;
  padding: 3px 10px;
  position: relative;
}
td:first-child {
  border-radius: 5px 0 0 5px;
  padding: 10px 0 10px 30px;
}
td:last-child {
  border-radius: 0 5px 5px 0;
}
.vertical-separator {
  border-left: 1px solid $grey;
  height: 60%;
}
.step-date {
  color: $yellow;
  font-size: 0.8em;
  margin-top: -5px;
}
.overlay {
  display: flex;
  flex-flow: column wrap;
  padding: 0;
  justify-content: flex-end;
  text-align: center;
  list-style: none;
  margin: 0;
}
</style>
