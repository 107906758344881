import {
  getUsersConnections,
  getUser,
  getTierFeaturesByInstitutionId,
} from '../services/account-mgt-service';
import { useStore } from '../store';
import { RoleIdBasedOnRoleName, User } from '../models';
import { computed } from 'vue';
import { RoleVerifierService } from '../services';
import { TierFeatureEnum } from '../models/tier-features';
import { useSelectedPhysicianStore } from '../store/useSelectedPhysicianStore';

export function useSurgeonFeaturesForNonPhysicianUsers() {
  const store = useStore();
  const selectedPhysicianStore = useSelectedPhysicianStore();

  const selectedPhysicianUserId = computed(() => {
    return selectedPhysicianStore.physicianUserId;
  });

  const selectionPhysicianInstitutionId = computed(() => {
    return selectedPhysicianStore.physicianInstitutionId;
  });

  const isPhysicianBetaUser = computed(() => {
    return selectedPhysicianStore.physicianBetaUser;
  });

  const physicianTierFeatures = computed<Array<TierFeatureEnum> | undefined>(
    () => {
      return selectedPhysicianStore.physicianTierFeatures;
    }
  );

  const initDefaultPhysician = async () => {
    return new Promise<void>(async (resolve, reject) => {
      if (
        store.currentUserInfo.userId === undefined ||
        RoleVerifierService.isPhysician() ||
        selectedPhysicianStore.isPhysicianSet
      ) {
        resolve();
        return;
      }
      try {
        const userConnections = await getUsersConnections(
          store.currentUserInfo.userId
        );
        const physicianConnections = userConnections.filter((user: User) => {
          return user.roleIds.includes(RoleIdBasedOnRoleName.PHYSICIAN);
        });

        if (!selectedPhysicianUserId.value && physicianConnections.length > 0) {
          const firstConnectionUserId = physicianConnections[0].userId;
          const firstConnectionInstitutionId =
            physicianConnections[0].institutionId!;
          await setPhysician(
            firstConnectionUserId,
            firstConnectionInstitutionId
          );
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  async function setPhysician(userId: string, institutionId: string) {
    let physicianUserId = userId;
    let physicianInstitutionId;
    let physicianTierFeaturesArr: TierFeatureEnum[] = [];
    let physicianBetaUser;

    const user: User = await getUser(userId);
    physicianInstitutionId = user.institutionId!;
    physicianBetaUser = user.isBetaUser;

    const userTiers = await getTierFeaturesByInstitutionId(
      physicianInstitutionId
    );
    physicianTierFeaturesArr = userTiers;

    selectedPhysicianStore.setPhysician(
      physicianUserId,
      physicianInstitutionId,
      physicianTierFeaturesArr,
      physicianBetaUser
    );
  }

  return {
    selectedPhysicianUserId,
    selectionPhysicianInstitutionId,
    physicianTierFeatures,
    isPhysicianBetaUser,
    initDefaultPhysician,
    setPhysician,
  };
}
