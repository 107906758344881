export const enum TierFeatureEnum {
  ACTIONS = 'ACTIONS', // Actions tab is not disabled
  CALENDAR = 'CALENDAR', // Calendar tab is not disabled
  CALENDAR_PLAN = 'CALENDAR_PLAN', // Access to Surgical Plan from Calendar is available
  ANALYTICS = 'ANALYTICS', // Analytics tab is not disabled
  PATIENTS = 'PATIENTS', // Patients tab is not disabled
  PATIENT_FILE = 'PATIENT_FILE', // Access to Patient file view
  PATIENT_FILE_EOS = 'PATIENT_FILE_EOS', // Access to EOS Exams
  PATIENT_FILE_ALIGNMENT = 'PATIENT_FILE_ALIGNMENT', // Access to Alignment Exams
  PATIENT_FILE_MOBILE = 'PATIENT_FILE_MOBILE', // Access to Mobile Exams
  PATIENT_FILE_IOA = 'PATIENT_FILE_IOA', // Access to IOA Exams
  PATIENT_FILE_ALIGNMENT_REPORTS = 'PATIENT_FILE_ALIGNMENT_REPORTS', // Access to Alignment Reports
  PATIENT_FILE_PLANNING_REPORTS = 'PATIENT_FILE_PLANNING_REPORTS', // Access to Surgical Planning Reports
  PATIENT_FILE_SURGICAL_PLAN = 'PATIENT_FILE_SURGICAL_PLAN', // Access to Surgical Plan in patient exams list
  PATIENT_FILE_STRATEGY = 'PATIENT_FILE_STRATEGY', // Access to Surgical Strategy Tab and Submit Button
  PATIENT_FILE_REQUEST_PLAN = 'PATIENT_FILE_REQUEST_PLAN', // Access to Request Plan button
  PATIENT_FILE_TRENDS = 'PATIENT_FILE_TRENDS', // Access to Patient Trends
  PLAN_GENERIC_INTERBODIES = 'PLAN_GENERIC_INTERBODIES', // Access to basic surgical planning (xx)
  PLAN_ATEC_INTERBODIES = 'PLAN_ATEC_INTERBODIES', // Access to advanced surgical planning (xx)
}
