import { useStore } from '../store';
import { i18nGlobalInstance } from '../assets/i18n/index';
import { User, UserShippingAddress } from '../models/user';
import { isSalesRep } from '../services/role-verifier-service';

const { t } = i18nGlobalInstance;

export function validateShippingAddress(
  user: User,
  isNotSalesRep: boolean = !isSalesRep()
): User | undefined {
  const store = useStore();

  if (!user?.shippingAddress) return user;

  if (isNotSalesRep || isShippingAddressEmpty(user.shippingAddress)) {
    delete user.shippingAddress;
    return user;
  }

  if (isShippingAddressFull(user.shippingAddress)) {
    return user;
  }

  const text = shippingAddressMissingValuesErrorText(
    getShippingAddressEmptyValues(user.shippingAddress)
  );
  store.toasts.push({
    id: 'userprofile-error-updated',
    type: 'error',
    text: text,
    duration: 3000,
    position: 'bottom-right',
  });
  return undefined;
}

export function verifyShippingAddress(
  shippingAddress: UserShippingAddress
): boolean {
  if (!isSalesRep() || isShippingAddressEmpty(shippingAddress)) return true;

  return false;
}

export function isShippingAddressEmpty(
  shippingAddress: UserShippingAddress
): boolean {
  delete shippingAddress.addressId;
  return Object.keys(shippingAddress).every((value) => {
    if (value === 'country') {
      return true;
    } else {
      return !shippingAddress[value as keyof UserShippingAddress];
    }
  });
}

export function isShippingAddressFull(
  shippingAddress: UserShippingAddress
): boolean {
  return Object.values(shippingAddress).every((value) => !!value);
}

export function getShippingAddressEmptyValues(
  shippingAddress: UserShippingAddress
): Array<string> {
  delete shippingAddress.addressId;

  return Object.keys(shippingAddress).filter(
    (key) => !shippingAddress[key as keyof UserShippingAddress]
  );
}

export function shippingAddressMissingValuesErrorText(
  missingValues: Array<string>
): string {
  let errorText = `${t('global.alert.missingShippingAddressValues')}`;
  missingValues.forEach((value) => {
    errorText += ` ${t(`global.input.${value}`)},`;
  });
  return errorText.slice(0, -1);
}
