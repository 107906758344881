import { useStore } from '../store';

export function isActive(): boolean {
  const store = useStore();
  return store.currentUserInfo.status === 'Active';
}

export function isNew(): boolean {
  const store = useStore();
  return store.currentUserInfo.status === 'New';
}

export function hasAcceptedEULA(): boolean {
  const store = useStore();
  return !!store.currentUserInfo.eulaAcknowledged;
}
