import { TierFeatureEnum } from '../models/tier-features';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useSessionStorage } from '@vueuse/core';

export const useSelectedPhysicianStore = defineStore(
  'selectedPhysician',
  () => {
    const physicianUserId = useSessionStorage<string | undefined>(
      'selectedPhysicianUserId',
      undefined
    );
    const physicianInstitutionId = useSessionStorage<string | undefined>(
      'selectedPhysicianInstitutionId',
      undefined
    );
    const physicianTierFeatures = useSessionStorage<
      Array<TierFeatureEnum> | undefined
    >('selectedPhysicianTierFeatures', undefined);
    const physicianBetaUser = useSessionStorage<boolean | undefined>(
      'selectedPhysicianBetaUser',
      undefined,
      {
        serializer: {
          read: (v) => (v === 'true' ? true : false),
          write: (v) => (v ? 'true' : 'false'),
        },
      }
    );

    const isPhysicianSet = computed(() => {
      return (
        physicianUserId.value !== undefined &&
        physicianInstitutionId.value !== undefined &&
        physicianTierFeatures.value !== undefined &&
        physicianBetaUser.value !== undefined
      );
    });

    function setPhysician(
      userId: string,
      institutionId: string,
      tierFeatures: TierFeatureEnum[],
      isBetaUser: boolean
    ) {
      physicianUserId.value = userId;
      physicianInstitutionId.value = institutionId;
      physicianTierFeatures.value = tierFeatures;
      physicianBetaUser.value = isBetaUser;
    }

    return {
      physicianUserId,
      physicianInstitutionId,
      physicianTierFeatures,
      physicianBetaUser,
      setPhysician,
      isPhysicianSet,
    };
  }
);
