<script lang="ts">
import { Vue, prop } from 'vue-class-component';

import { DdpService } from '../../../services';

export interface FilePair {
  file: File;
  error: boolean | undefined;
  pending: boolean;
}

class Props {
  hidePanel = prop({
    type: Boolean,
    required: false,
    default: true,
  });
}

export default class DicomImporter extends Vue.with(Props) {
  showPanel = false;
  filesListArray: Array<FilePair> = [];

  async created(): Promise<void> {
    this.$watch(
      'hidePanel',
      () => {
        this.showPanel = !this.hidePanel;
      },
      {
        immediate: true,
      }
    );
  }

  async submitFormImportDicom(): Promise<void> {
    for (const filePair of this.filesListArray) {
      filePair.pending = true;
      filePair.error = !(await DdpService.importDicomFile(filePair.file));
      filePair.pending = false;
    }
  }

  changeFileImportDicom(event: Event): void {
    const ev = event.target as HTMLInputElement;
    if (ev.files && ev.files.length > 0) {
      for (let i = 0; i < ev.files.length; i++) {
        const file = ev.files.item(i) as File;
        if (!this.filesListArray.map((x) => x.file.name).includes(file.name)) {
          this.filesListArray.push({
            file: file,
            error: undefined,
            pending: false,
          });
        }
      }
    }
  }

  clearFileImportDicom(): void {
    this.filesListArray = [];
  }
}
</script>

<template>
  <div class="flex" style="margin-top: 20px; position: relative">
    <div class="showPanelImport">
      <button
        style="height: 42px"
        id="dicom-importer-show"
        class="tertiaryButton button flex"
        @click="showPanel = !showPanel"
      >
        {{ $t('worklist.importTitle') }}
        <svg
          v-if="!showPanel"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
          fill="#B1B2B3"
        >
          <path d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6Z" />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
          fill="#B1B2B3"
        >
          <path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z" />
        </svg>
      </button>
    </div>
    <div v-show="showPanel" class="panel importDiv">
      <form id="dicom-importer-form" @submit.prevent="submitFormImportDicom">
        <label for="dicom-importer-input" class="import tertiaryButton">
          {{ $t('worklist.importLabel') }}
        </label>
        <table aria-describedby="files list">
          <thead v-show="false">
            <!-- avoid an error SonarQube -->
            <tr>
              <th scope="col">{{ $t('global.button.filename') }}</th>
              <th scope="col">{{ $t('global.button.delete') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(filePair, index) in filesListArray"
              :key="index"
              class="container"
            >
              <td
                :class="
                  filePair.error === undefined
                    ? ''
                    : filePair.error === true
                    ? 'importError'
                    : 'importSuccess'
                "
              >
                {{ filePair.file.name }}
              </td>
              <td>
                <svg
                  v-show="!filePair.pending"
                  @click="filesListArray.splice(index, 1)"
                  xmlns="http://www.w3.org/2000/svg"
                  class="fillOnHover"
                  height="24"
                  width="24"
                  style="cursor: pointer"
                >
                  <path
                    d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z"
                  />
                </svg>
                <div v-show="filePair.pending" class="loader">
                  <img src="../../../assets/img/loader.svg" alt="loader" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <input
          type="file"
          id="dicom-importer-input"
          multiple
          @change="(event) => changeFileImportDicom(event)"
        />
        <div class="flex">
          <button
            id="dicom-importer-clear"
            type="reset"
            @click="clearFileImportDicom"
            class="tertiaryButton button"
            style="margin-right: 20px"
          >
            {{ $t('global.button.clear') }}
          </button>
          <button
            id="dicom-importer-validate"
            type="submit"
            class="validate button"
          >
            {{ $t('global.button.import') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/css/global.scss';

input[type='file'] {
  display: none;
}

.importDiv {
  button {
    margin-top: 20px;
  }
  .import {
    background-color: $black;
    border: 1px solid $bordergrey;
    border-radius: 6px;
    font-size: 1em;
    transition: all 0.5s ease;
    &:hover {
      border: 1px solid $lightgrey;
    }
    display: inline-block;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .importError {
    border-bottom: 1px solid $red;
  }
  .importSuccess {
    border-bottom: 1px solid $lightgreen;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.showPanelImport {
  margin-right: 20px;
}

.panel {
  background-color: $black;
  border: 1px solid $lightgrey;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 50px;
  padding: 20px;
  width: max-content;
  z-index: 5;
}

.loader {
  width: 24px;
}

.fillOnHover {
  fill: #b1b2b3;
  &:hover {
    fill: $white;
  }
}
</style>
