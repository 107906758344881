<script lang="ts">
import { Vue } from 'vue-class-component';
import { AuthenticationService } from '../../../services';
import { deregisterIdleTimer } from 'atec-cloud-library-inactivity';

export default class LogoutButton extends Vue {
  async logout(event: Event): Promise<void> {
    event.stopPropagation(); // To prevent triggering global document listener for user session refresh
    deregisterIdleTimer();
    await AuthenticationService.logout();
  }
}
</script>

<template>
  <li id="navigation-logout" :title="$t('global.logout')" @click="logout">
    <a style="text-decoration: none" href="#">
      <slot></slot>
    </a>
  </li>
</template>

<style lang="scss" scoped>
@import '../../../assets/css/global.scss';

a {
  pointer-events: none;
}
li {
  padding: 10px 0 0 0;
}
li:hover {
  cursor: pointer;
  svg {
    fill: $orange;
  }
}

.testLink__text {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  color: #f5f5f5;
}
</style>
