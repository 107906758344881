<script lang="ts" setup>
import { defineProps, onMounted } from 'vue';
import { PatientDetailsToast } from '../../../models/toast';
import { ToastNotificationService } from '../../../services/toast-notification-service';
import { useStore } from '../../../store';

const toastNotificationService = new ToastNotificationService();
interface Props {
  toast: PatientDetailsToast;
  index: number;
}
const props = defineProps<Props>();

const store = useStore();
onMounted(() => {
  setTimeout(() => {
    store.clearToast(props.toast.id);
  }, props.toast.duration);
});
</script>

<template>
  <div class="toast-box">
    {{ toast.text }}
    <div class="right-side-toast">
      <div
        class="undo-button"
        v-if="(toast as PatientDetailsToast).callableFunction"
        @click="(toast as PatientDetailsToast).callableFunction"
      >
        {{ $t('global.undo') }}
      </div>
      <div class="close-icon" @click="toastNotificationService.remove(index)">
        <img src="../../../assets/img/toast-close.svg" alt="close" />
      </div>
    </div>
  </div>
</template>
