import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useOfflineDetectionStore } from './useOfflineDetectionStore';
import {
  ErrorModalButtonEnum,
  ErrorModalInformation,
} from '../models/global-errors';
import { useResolutionDetectionStore } from './useResolutionDetectionStore';
import { i18nGlobalInstance } from '../assets/i18n/index';

export const useGlobalErrorsStore = defineStore('globalErrors', () => {
  const offlineDetectionStore = useOfflineDetectionStore();
  const resolutionDetectionStore = useResolutionDetectionStore();
  const errorCode = ref(-1);
  // note: 999 is genericError
  // note: 998 is resolutionTooLow

  const errorModalInformation = computed<ErrorModalInformation>(() => {
    if (
      errorCode.value === 400 ||
      errorCode.value === 500 ||
      errorCode.value === 999
    ) {
      return {
        isError: true,
        code: errorCode.value,
        title: i18nGlobalInstance.t('global.error.somethingWentWrong'),
        text: i18nGlobalInstance.t('global.error.errorEncounteredText'),
        buttons: [ErrorModalButtonEnum.Refresh, ErrorModalButtonEnum.Cancel],
      };
    } else if (errorCode.value === 499 || offlineDetectionStore.isOffline) {
      return {
        isError: true,
        code: errorCode.value,
        title: i18nGlobalInstance.t('global.error.offlineDetection'),
        text: i18nGlobalInstance.t('global.error.offlineDetectionText'),
        buttons: [ErrorModalButtonEnum.Refresh],
      };
    } else if (errorCode.value === 998) {
      return {
        isError: true,
        code: errorCode.value,
        title: i18nGlobalInstance.t('global.error.resolutionTooLowTitle'),
        text: i18nGlobalInstance.t('global.error.resolutionTooLowText'),
        buttons: [],
      };
    }

    return {
      isError: false,
      code: errorCode.value,
      title: '',
      text: '',
      buttons: [],
    };
  });

  watch(
    () => resolutionDetectionStore.isResolutionTooLow,
    (val) => {
      if (val) {
        errorCode.value = 998;
      } else if (!val && errorCode.value === 998) {
        clearError();
      }
    }
  );

  watch(
    () => offlineDetectionStore.isOffline,
    (val) => {
      if (val) {
        errorCode.value = 499;
      } else if (!val && errorCode.value === 499) {
        clearError();
      }
    }
  );

  function setErrorCode(code: number) {
    errorCode.value = code;
  }

  function showGenericError(textOrError?: string | any) {
    console.error(textOrError);
    if (errorCode.value === -1) {
      errorCode.value = 999;
    }
  }

  function clearError() {
    errorCode.value = -1;
  }

  function isError() {
    return errorCode.value !== -1;
  }

  function isResolutionError() {
    return errorCode.value === 998;
  }

  return {
    errorModalInformation,
    errorCode,
    setErrorCode,
    showGenericError,
    clearError,
    isError,
    isResolutionError,
  };
});
