/* eslint-disable */
import { defineStore } from 'pinia';
import { i18n } from '../assets/i18n';

import { WorkspaceConfig } from '../assets/config';
import {
  Acquisition,
  Application,
  EMPTY_PAGINATED_RES,
  Institution,
  Role,
  State,
  Toast,
  User,
} from '../models';
import {
  AccountMgtService,
  ApplicationLauncherService,
  PatientListService,
} from '../services';

const StateAbbreviations: { [key: string]: string } = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

const booleanStatuses = [
  {
    value: true,
    text: i18n.global.t('global.status.Active'),
  },
  {
    value: false,
    text: i18n.global.t('global.status.Deactivated'),
  },
];

const statuses = [
  {
    value: 'Active',
    text: i18n.global.t('global.status.Active'),
  },
  {
    value: 'Deactivated',
    text: i18n.global.t('global.status.Deactivated'),
  },
  {
    value: 'Deleted',
    text: i18n.global.t('global.status.Deleted'),
  },
  {
    value: 'New',
    text: i18n.global.t('global.status.New'),
  },
  {
    value: 'Pending',
    text: i18n.global.t('global.status.Pending'),
  },
  {
    value: 'Reset',
    text: i18n.global.t('global.status.Reset'),
  },
];

export const useStore = defineStore('Store', {
  state: (): State => {
    return {
      aboutConfig: {
        namespace: '',
        product: {
          name: '',
          version: '',
        },
        manufacturer: {
          address: '',
          city: '',
          state: '',
          zip: '',
          name: '',
          phoneNumber: '',
        },
        uniqueDeviceIdentification: '',
      },
      applications: new Array<Application>(),
      booleanStatuses: booleanStatuses,
      currentUserInfo: {} as User,
      exams: new Array<Acquisition>(),
      institutions: new Array<Institution>(),
      pendingUsers: new Array<User>(),
      roles: new Array<Role>(),
      states: StateAbbreviations,
      statuses: statuses,
      totalExams: 0,
      totalInstitutions: 0,
      totalPendingUsers: 0,
      totalUsers: 0,
      users: new Array<User>(),
      toasts: [] as Array<Toast>,
    };
  },
  getters: {},
  actions: {
    async getInstitutions(params?: {
      [x: string]: number | string;
    }): Promise<Institution[]> {
      const institutionList =
        (await AccountMgtService.getInstitutions(params)) ??
        EMPTY_PAGINATED_RES;
      // Initial call
      if (params?.page === 1) {
        this.institutions = new Array<Institution>();
        this.totalInstitutions = 0;
      }
      (institutionList.items as Array<Institution>).forEach(
        (institution: Institution) => {
          this.institutions.push(institution);
        }
      );

      // Last call
      if (params?.page !== 1 && institutionList.totalCount === 0) {
        return this.institutions;
      }

      this.totalInstitutions = institutionList.totalCount;
      return this.institutions;
    },

    async getUsers(params?: {
      [x: string]: string | number | string[] | string[][];
    }): Promise<void> {
      const userList =
        (await AccountMgtService.getUsers(params)) ?? EMPTY_PAGINATED_RES;
      // Initial call
      if (params?.page === 1) {
        this.users = new Array<User>();
        this.totalUsers = 0;
      }

      (userList.items as Array<User>).forEach((user: User) => {
        if (!this.users.map((u) => u.userId).includes(user.userId)) {
          this.users.push(user);
        }
      });

      // Last call
      if (params?.page !== 1 && userList.totalCount === 0) {
        return;
      }

      this.totalUsers = userList.totalCount;
    },

    async getPendingUsers(params?: {
      [x: string]: string | number | string[] | string[][];
    }): Promise<void> {
      const requestList =
        (await AccountMgtService.getUsers(params)) ?? EMPTY_PAGINATED_RES;
      // Initial call
      if (params?.page === 1) {
        this.pendingUsers = new Array<User>();
        this.totalPendingUsers = 0;
      }

      (requestList.items as Array<User>).forEach((user: User) => {
        this.pendingUsers.push(user);
      });

      // Last call
      if (params?.page !== 1 && requestList.totalCount === 0) {
        return;
      }
      this.totalPendingUsers = requestList.totalCount;
    },

    resetUsers(): void {
      this.users = new Array<User>();
      this.totalUsers = 0;
    },

    async getRoles(): Promise<void> {
      const roleList = await AccountMgtService.getRoles();
      this.roles = roleList ?? [];
    },

    async getExams(params?: { [x: string]: string | number }): Promise<void> {
      const examList =
        (await PatientListService.getExams(params)) ?? EMPTY_PAGINATED_RES;
      // Initial call
      if (params?.page === 1) {
        this.exams = new Array<Acquisition>();
        this.totalExams = 0;
      }

      (examList.items as Array<Acquisition>).forEach(
        (acquisition: Acquisition) => {
          this.exams.push(acquisition);
        }
      );
      this.totalExams = examList.totalCount;
    },

    resetExams(): void {
      this.exams = new Array<Acquisition>();
      this.totalExams = 0;
    },

    async getApplications(): Promise<void> {
      const appList = await ApplicationLauncherService.getApplications();
      this.applications = appList ?? [];
    },

    async getUserInfo(): Promise<void> {
      const user = await AccountMgtService.getCurrentUser();
      if (user !== undefined) {
        this.currentUserInfo = user;
      }
    },

    async getAbout(): Promise<void> {
      //const aboutConfig = await AccountMgtService.getAbout();
      const aboutConfig = WorkspaceConfig.instance().appAboutConfig();
      this.aboutConfig = aboutConfig ?? this.aboutConfig;
    },

    getAbbreviationForState(stateName: string): string {
      return StateAbbreviations[stateName];
    },

    getFullState(stateName: string): string {
      const stateFullName = Object.keys(StateAbbreviations).find(
        (key) => StateAbbreviations[key] === stateName
      );
      return `${stateFullName} (${stateName}) `;
    },

    getStateFullName(stateName: string): string {
      const stateFullName = Object.keys(StateAbbreviations).find(
        (key) => StateAbbreviations[key] === stateName
      );
      return stateFullName ?? stateName;
    },

    addToast(toast: Toast) {
      this.toasts.push(toast);
    },

    clearToast(id: string | number) {
      const index = this.toasts.findIndex(
        (toast: { id: string | number }) => toast.id === id
      ); // find toast
      this.toasts.splice(index, 1); // remove toast from array
    },
  },
});
